/* eslint-disable no-console */
import { ethers } from 'ethers';
import { CorporationManager } from 'corporation-core-sdk';
import { NriCoin__factory as NriCoinFactory } from 'nricoin';
import { AfTansferModel, dexieDb } from '../dexie';
import { Network } from './network';

export class AfTansfer {
  private chainIds: string;

  private scannedNumber: number;

  private eventList: AfTansferModel[];

  constructor(eventList: AfTansferModel[], chainId: string, scannedNumber: number) {
    this.eventList = eventList;
    this.chainIds = chainId;
    this.scannedNumber = scannedNumber;
  }

  async getEventList(network: any, lpAddress: string, offset: number, pageSize: number) {
    const eventList = await dexieDb.afTansfer
      .orderBy('transactionId')
      .reverse()
      .filter(event => event.chainId === network.chainId && event.lpAddress === lpAddress)
      .offset(offset)
      .limit(pageSize)
      .toArray();
    this.eventList = eventList;
    return eventList;
  }

  async getHistoryList(network: any, lpAddress: string) {
    const eventList = await dexieDb.afTansfer
      .orderBy('transactionId')
      .reverse()
      .filter(event => event.chainId === network.chainId && event.lpAddress === lpAddress && event.status === 1)
      .toArray();
    this.eventList = eventList;
    return eventList;
  }

  async getEventListByContract(network: any, walletAddress: string, offset: number, contractAddress: string, pageSize: number) {
    const eventList = await dexieDb.afTansfer
      .orderBy('transTime')
      .reverse()
      .filter(event => event.chainId === network.chainId && event.address === walletAddress && event.contractAddress === contractAddress)
      .offset(offset)
      .limit(pageSize)
      .toArray();
    this.eventList = eventList;
    return eventList;
  }

  async getEventListAll(network: any, lpAddress: string) {
    const eventCount = await dexieDb.afTansfer
      .where(['chainId+lpAddress'])
      .equals([network.chainId, lpAddress])
      .count()
    this.chainIds = network.chainId;
    return eventCount;
  }

  async getEventListAllByContract(network: any, walletAddress: string, contractAddress: string) {
    const eventCount = await dexieDb.afTansfer
      .where(['chainId+address+contractAddress'])
      .equals([network.chainId, walletAddress, contractAddress])
      .count()
    this.chainIds = network.chainId;
    return eventCount;
  }

  async add(transactionId: number, chainId: string, contractAddress: string, lpAddress: string, walletAddress: string, transactionHash: string, symbol: string, number: number, from: string, to: string, value: string, executed: boolean, status: number, isOperate: number) {
    const event: AfTansferModel | undefined = {
      transactionId,
      chainId,
      contractAddress,
      lpAddress,
      address: walletAddress,
      transactionHash,
      symbol,
      number,
      from,
      to,
      value,
      executed,
      status,
      isOperate
    };
    this.chainIds = chainId;
    try {
      await dexieDb.afTansfer.put(event);
    } catch (e) {
      console.error(e);
    }

  }

  getScannedNumber() {
    return this.scannedNumber;
  }

  async setScannedNumber(scannedNumber: number, lpAddress: string, network: Network) {
    const chainId = network?.chainId;
    if (chainId) {
      await dexieDb.number.put({
        lpAddress,
        chainId: chainId as string,
        number: scannedNumber,
      });
    }
    this.scannedNumber = scannedNumber;
  }

  async scan(CorporationMgr: CorporationManager, latestNumber: number, network: Network, walletAddress: string, lpAddress: string, scanUnit = 10) {
    const scannedNumber = (await dexieDb.number.get([lpAddress, this.chainIds]))?.number || 0;
    const fromNumber = scannedNumber;
    if (fromNumber < latestNumber) {
      // 调用SDK获取申请
      const tevntFrom = await CorporationMgr.getTransactions(walletAddress, fromNumber, latestNumber, 7, false)
      const toNumber = fromNumber + scanUnit > latestNumber ? latestNumber : fromNumber + scanUnit;
      let isUpdate = false;
      const chainId = this.chainIds;
      if (tevntFrom) {
        if (tevntFrom.length > 0) {
          for (const event of tevntFrom) {
            const nriCoin = new ethers.Contract(event.destination, NriCoinFactory.abi);
            const transfer = nriCoin.interface.decodeFunctionData("transfer", event.data);
            const transactionId = Number(event.transactionId);
            const contractAddress = event.destination;
            const transactionHash = '';
            const symbols = event.symbol;
            const number = latestNumber;
            const from = lpAddress;
            const to = transfer.to.toString();
            const value = Number(ethers.utils.formatUnits(transfer.amount.toString(), event.decimals));
            const executeds = event.executed;
            const statuss = event.status;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const valueInt = parseInt(value, 10);
            if ((Number(value) - valueInt) > 0) {
              this.add(transactionId, chainId, contractAddress, lpAddress, walletAddress, transactionHash, symbols, number, from, to, value.toString(), executeds, statuss, 0);
            } else {
              this.add(transactionId, chainId, contractAddress, lpAddress, walletAddress, transactionHash, symbols, number, from, to, valueInt.toString(), executeds, statuss, 0);
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isUpdate = true;
          }
        }
      }

      await this.setScannedNumber(toNumber, lpAddress, network);

      return {
        eventList: this.eventList,
        isUpdate,
        endNumber: toNumber,
        hasNext: toNumber < latestNumber,
      };
    }
    return {
      eventList: this.eventList,
      isUpdate: false,
      endNumber: scannedNumber,
      hasNext: false,
    };
  }

  static async load(lpAddress: string, network: Network) {
    const eventList = await dexieDb.afTansfer
      .where({ chainId: network.chainId, lpAddress })
      .reverse()
      .toArray()
    const scannedNumber = (await dexieDb.number.get([lpAddress, network.chainId,]))?.number || 0;
    const activityMgr = new AfTansfer(eventList, network.chainId, scannedNumber);
    return activityMgr;
  }
}

export default AfTansfer;
