/* eslint-disable no-alert */

/* eslint-disable no-console */
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Snackbar,
  TextField,
} from '@mui/material';
import { CorporationManager } from 'corporation-core-sdk';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Wallet, ethers } from 'ethers';
import ServiceManager from '../helpers/ServiceManager';
import { useContextSet } from '../helpers/common';
import LpAccount from '../helpers/lpAccount';
import { TransActivity } from '../helpers/transActivity';

export function PageLpAccount() {
  const { residentContext } = useContextSet();
  const { wallet, network, lpAccount, token } = residentContext;
  const [snackbar, setSnackbar] = useState<string>();
  const [snackbarError, setSnackbarError] = useState<string>();
  const [passwordInput, setPasswordInput] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [lpAccountList, setLpAccountList] = useState([]);
  const [createOpenFlag, setCreateOpenFlag] = useState(false);
  const [appendOpenFlag, setAppendOpenFlag] = useState(false);
  const [detailOpenFlag, setDetailOpenFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [verifyInput, setVerifyInput] = useState([{ id: '', address: '' }]);
  const [applyInput, setApplyInput] = useState([{ id: '', address: '' }]);
  const [requiredInput, setRequiredInput] = useState('');
  const [lpAccountName, setLpAccountName] = useState('');
  const [lpName, setLpName] = useState('');
  const [lpAddress, setLpAddress] = useState('');
  const [lpDetailName, setLpDetailName] = useState('');
  const [lpDetailAddress, setLpDetailAddress] = useState('');
  const [requiredDetailInput, setRequiredDetailInput] = useState('');
  const [formError, setFromError] = useState(false);
  const [formError1, setFromError1] = useState(false);
  const [loading, setLoading] = useState(false);

  const getWallet = useCallback(async () => {
    if (lpAccount && lpAccount.walletList) {
      const list = [];
      for (let index = 0; index < lpAccount.walletList.length; index += 1) {
        if (lpAccount.walletList[index].chainId === network?.chainId) {
          list.push(lpAccount.walletList[index])
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setLpAccountList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpAccount?.address]);

  useEffect(() => {
    getWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpAccount?.address]);

  const showSnackbar = (msg: string) => {
    setSnackbar(msg);
  };

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.id) {
      setPasswordInput(() => event.target.value);
      setPasswordError(false);
    }
  };

  const closeSnackbar = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(undefined);
  };

  const closeSnackbarError = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarError(undefined);
  };

  if (!network || !wallet) {
    return <Container />;
  }

  const handleClose = () => {
    setOpen(false);
    setPasswordError(false);
  };

  const deleteLpAccount = async (item: any) => {
    // 调用sdk删除钱包

    if (lpAccount) {
      const list = [];
      for (let index = 0; index < lpAccount.walletList.length; index += 1) {
        if (item.address !== lpAccount.walletList[index].address) {
          list.push(lpAccount.walletList[index]);
        }
      }
      if (lpAccount.address === item.address) {
        if (list.length > 0) {
          lpAccount.name = list[0].name;
          lpAccount.address = list[0].address;
          lpAccount.permits = list[0].permits;
          lpAccount.confirmers = list[0].confirmers;
          lpAccount.required = list[0].required;
          lpAccount.chainId = list[0].chainId;
        }
        if (list.length === 0) {
          lpAccount.clear();
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setLpAccountList(list);
      lpAccount.walletList = list;
      await lpAccount.save();
      residentContext.setLpAccount(await LpAccount.load(network));
      showSnackbar('アカウントを削除しました。');
    }
  };

  const createOpen = () => {
    setCreateOpenFlag(true);
  };

  const appendOpen = () => {
    setAppendOpenFlag(true);
  };

  const editLpAccount = (item: any) => {
    const applylist = [];
    for (let index = 0; index < item.permits.length; index += 1) {
      const iid = new Date().getTime().toString() + index;
      const applyInfo = { id: iid, address: item.permits[index].address };
      applylist.push(applyInfo);
    }
    const verifylist = [];
    for (let index = 0; index < item.confirmers.length; index += 1) {
      const iid = new Date().getTime().toString() + index;
      const verifyInfo = { id: iid, address: item.confirmers[index].address };
      verifylist.push(verifyInfo);
    }
    setLpDetailName(item.name);
    setLpDetailAddress(item.address);
    setApplyInput(applylist);
    setVerifyInput(verifylist);
    setRequiredDetailInput(item.required);
    setDetailOpenFlag(true);
  };

  const editLpAccountClose = () => {
    setLpDetailName('');
    setLpDetailAddress('');
    setApplyInput([{ id: '', address: '' }]);
    setVerifyInput([{ id: '', address: '' }]);
    setDetailOpenFlag(false);
  };

  const createClose = () => {
    setLpAccountName('');
    setApplyInput([{ id: '', address: '' }]);
    setVerifyInput([{ id: '', address: '' }]);
    setRequiredInput('');
    setCreateOpenFlag(false);
  };

  const appendClose = () => {
    setLpName('');
    setLpAddress('');
    setAppendOpenFlag(false);
  };

  const addVerifyInput = () => {
    const iid = new Date().getTime().toString();
    const list = [...verifyInput];
    const verifyInfo = { id: iid, address: '' };
    list.push(verifyInfo);
    setVerifyInput(list);
  };

  const addApplyInput = () => {
    const iid = new Date().getTime().toString();
    const list = [...applyInput];
    const applyInfo = { id: iid, address: '' };
    list.push(applyInfo);
    setApplyInput(list);
  };

  const deleteVerifyInput = (item: any) => {
    const list = [];
    for (let index = 0; index < verifyInput.length; index += 1) {
      if (item.id !== verifyInput[index].id) {
        list.push(verifyInput[index]);
      }
    }
    setVerifyInput(list);
  };

  const deleteApplyInput = (item: any) => {
    const list = [];
    for (let index = 0; index < applyInput.length; index += 1) {
      if (item.id !== applyInput[index].id) {
        list.push(applyInput[index]);
      }
    }
    setApplyInput(list);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'name':
        setFromError(false);
        setLpAccountName(event.target.value);
        break;
      case 'applyAddress':
        const applyList = [];
        for (let index = 0; index < applyInput.length; index += 1) {
          if (event.target.name === applyInput[index].id) {
            const apply = { id: applyInput[index].id, address: event.target.value };
            applyList.push(apply);
          }
          if (event.target.name !== applyInput[index].id) {
            applyList.push(applyInput[index]);
          }
        }
        setApplyInput(applyList);
        break;
      case 'verifyAddress':
        const verifyList = [];
        for (let index = 0; index < verifyInput.length; index += 1) {
          if (event.target.name === verifyInput[index].id) {
            const verify = { id: verifyInput[index].id, address: event.target.value }
            verifyList.push(verify);
          }
          if (event.target.name !== verifyInput[index].id) {
            verifyList.push(verifyInput[index]);
          }
        }
        setVerifyInput(verifyList);
        break;
      case 'requiredNumber':
        setRequiredInput(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleChange1 = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'lpName':
        setLpName(event.target.value);
        setFromError(false);
        break;
      case 'lpAddress':
        setLpAddress(event.target.value);
        setFromError1(false);
        break;
      default:
        break;
    }
  };

  const handleChange2 = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'lpDetailName':
        setFromError(false);
        setLpDetailName(event.target.value);
        break;
      case 'applyAddress':
        const applyList = [];
        for (let index = 0; index < applyInput.length; index += 1) {
          if (event.target.name === applyInput[index].id) {
            const apply = { id: applyInput[index].id, address: event.target.value };
            applyList.push(apply);
          }
          if (event.target.name !== applyInput[index].id) {
            applyList.push(applyInput[index]);
          }
        }
        setApplyInput(applyList);
        break;
      case 'verifyAddress':
        const verifyList = [];
        for (let index = 0; index < verifyInput.length; index += 1) {
          if (event.target.name === verifyInput[index].id) {
            const verify = { id: verifyInput[index].id, address: event.target.value }
            verifyList.push(verify);
          }
          if (event.target.name !== verifyInput[index].id) {
            verifyList.push(verifyInput[index]);
          }
        }
        setVerifyInput(verifyList);
        break;
      case 'requiredDetailNumber':
        setRequiredDetailInput(event.target.value);
        break;
      default:
        break;
    }
  };

  const createLpAccount = async (owner: Wallet) => {
    if (!network) {
      alert('Networkを作成をお願いします。')
      return;
    }

    if (!wallet.address) {
      alert('Walletを作成をお願いします。')
      return;
    }
    setLoading(true);
    try {
      // 调用sdk添加钱包
      const CorporationMgr = new CorporationManager();
      const permit: any[] = [];
      applyInput.forEach((item: any) => {
        if (item.address !== '') {
          permit.push(item.address)
        }
      })
      const confirmer: any[] = [];
      verifyInput.forEach((item: any) => {
        if (item.address !== '') {
          confirmer.push(item.address)
        }
      })
      const lpNewAddress = await CorporationMgr.deploy(owner, permit, confirmer, Number(requiredInput));
      setLoading(false);
      const lpAccountInfo = { name: '', address: '', permits: [], confirmers: [], required: '', chainId: '', walletList: [] };
      if (lpAccount) {
        if (!lpAccount.address) {
          lpAccount.name = lpAccountName;
          lpAccount.address = lpNewAddress;
          lpAccount.permits = applyInput;
          lpAccount.confirmers = verifyInput;
          lpAccount.required = requiredInput;
          lpAccount.chainId = network.chainId;
        }
        lpAccountInfo.name = lpAccountName;
        lpAccountInfo.address = lpNewAddress;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lpAccountInfo.permits = applyInput;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lpAccountInfo.confirmers = verifyInput;
        lpAccountInfo.required = requiredInput;
        lpAccountInfo.chainId = network.chainId;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lpAccountList.push(lpAccountInfo)
        lpAccount.walletList = lpAccountList;
        await lpAccount.save();
        lpAccount.setWallet(network, lpAccount.address);
        showSnackbar('アカウントを作成しました。');
        residentContext.setLpAccount(await LpAccount.load(network));
        const block = await network.nriProvider.getBlockNumber();
        const transActivity = new TransActivity([], "", 0);
        if (token) {
          const contractLists = [{ address: '', symbol: '', decimal: '18', rpcUrl: '', name: '' }];
          contractLists.pop()
          token.contractList?.forEach((item: any) => {
            if (item.rpcUrl === network?.rpcUrl) {
              contractLists.push(item)
            }
          })
          contractLists.forEach((item: any) => {
            transActivity.setScannedBlock(block, lpNewAddress, item.address, network);
            wallet.loadActivity(network, item.address);
            wallet.setWallet(network, item.address);
          })  
        }

      }
    } catch (error) {
      setSnackbarError('アカウントを作成に失敗しました。');
      setLoading(false);
      return;
    }
    createClose();
  };

  const updateLpAccount = async (owner: Wallet) => {
    setLoading(true);
    // 调用sdk修改钱包
    const CorporationMgr = new CorporationManager();
    const permit: any[] = [];
    applyInput.forEach((item: any) => {
      if (item.address !== '') {
        permit.push(item.address)
      }
    })
    const confirmer: any[] = [];
    verifyInput.forEach((item: any) => {
      if (item.address !== '') {
        confirmer.push(item.address)
      }
    })
    try {
      await CorporationMgr.connect(lpDetailAddress, network.rpcUrl);
      const results = await CorporationMgr.checkIdentity(wallet.address);
      if (results[0].toString() !== '1') {
        setSnackbarError('アカウントの修正に失敗しました。');
        setLoading(false);
        return;
      }
      await CorporationMgr.updateMultiSigWallet(owner, permit, confirmer, Number(requiredDetailInput));
      const lpAccountInfo = { name: '', address: '', permits: [], confirmers: [], required: '', chainId: '', walletList: [] };
      if (lpAccount) {
        if (lpAccount.address === lpDetailAddress) {
          lpAccount.name = lpDetailName;
          lpAccount.address = lpDetailAddress;
          lpAccount.permits = applyInput;
          lpAccount.confirmers = verifyInput;
          lpAccount.required = requiredDetailInput;
        }
        lpAccountInfo.name = lpDetailName;
        lpAccountInfo.address = lpDetailAddress;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lpAccountInfo.permits = applyInput;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lpAccountInfo.confirmers = verifyInput;
        lpAccountInfo.chainId = network.chainId;
        lpAccountInfo.required = requiredDetailInput;
        const list = [];
        for (let index = 0; index < lpAccount.walletList.length; index += 1) {
          if (lpDetailAddress === lpAccount.walletList[index].address) {
            list.push(lpAccountInfo);
          }
          if (lpDetailAddress !== lpAccount.walletList[index].address) {
            list.push(lpAccount.walletList[index]);
          }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setLpAccountList(list);
        lpAccount.walletList = list;
        await lpAccount.save();
        showSnackbar('アカウントを修正しました。');
        residentContext.setLpAccount(await LpAccount.load(network));
      }
    } catch (error) {
      setSnackbarError('アカウントの修正に失敗しました。');
      return;
    }
    setLoading(false);
    editLpAccountClose();
  };

  const addLpAccount = async () => {
    if (!network) {
      alert('Networkを作成をお願いします。')
      return;
    }

    if (!wallet.address) {
      alert('Walletを作成をお願いします。')
      return;
    }

    if (lpName === '') {
      setFromError(true);
      return;
    }
    if (lpAddress === '') {
      setFromError1(true);
      return;
    }
    let flag = false;
    lpAccountList.forEach((item: any) => {
      if (item.address === lpAddress) {
        flag = true;
        alert('アカウントは既に存在します。')
      }
    })
    if (flag) {
      return
    }
    setLoading(true);
    try {
      // 调用sdk获取钱包
      const CorporationMgr = new CorporationManager();
      await CorporationMgr.connect(lpAddress, network.rpcUrl);
      const results = await CorporationMgr.checkIdentity(wallet.address);
      if (results[0].toString() === '0') {
        setSnackbarError('アカウントを追加に失敗しました。');
        setLoading(false);
        return;
      }
      const permitList = await CorporationMgr.getPermits();
      const confirmerList = await CorporationMgr.getConfirmers();
      const requireds = await CorporationMgr.getRequired();
      const applyList = [];
      for (let index = 0; index < permitList.length; index += 1) {
        const iid = new Date().getTime().toString();
        const applyInfo = { id: iid, address: permitList[index] };
        applyList.push(applyInfo)
      }
      const verifyList = [];
      for (let index = 0; index < confirmerList.length; index += 1) {
        const iid = new Date().getTime().toString();
        const applyInfo = { id: iid, address: confirmerList[index] };
        verifyList.push(applyInfo)
      }
      const lpAccountInfo = { name: lpName, address: lpAddress, permits: applyList, confirmers: verifyList, required: requireds.toString(), chainId: network.chainId };
      if (lpAccount) {
        if (!lpAccount.address) {
          lpAccount.name = lpName;
          lpAccount.address = lpAddress;
          lpAccount.permits = applyList;
          lpAccount.confirmers = verifyList;
          lpAccount.required = requireds.toString();
          lpAccount.chainId = network.chainId;
        }
        const list = [];
        for (let index = 0; index < lpAccount.walletList?.length; index += 1) {
          list.push(lpAccount.walletList[index]);
        }
        list.push(lpAccountInfo)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setLpAccountList(list);
        lpAccount.walletList = list;
        await lpAccount.save();
        lpAccount.setWallet(network, lpAccount.address);
        setLoading(false);
        showSnackbar('アカウントを追加しました。');
        residentContext.setLpAccount(await LpAccount.load(network));
        const block = await network.nriProvider.getBlockNumber();
        const transActivity = new TransActivity([], "", 0);
        if (token) {
          const contractLists = [{ address: '', symbol: '', decimal: '18', rpcUrl: '', name: '' }];
          contractLists.pop()
          token.contractList?.forEach((item: any) => {
            if (item.rpcUrl === network?.rpcUrl) {
              contractLists.push(item)
            }
          })
          contractLists.forEach((item: any) => {
            transActivity.setScannedBlock(block, lpAddress, item.address, network);
            wallet.loadActivity(network, item.address);
            wallet.setWallet(network, item.address);
          })  
        }
      }
    } catch (error) {
      setSnackbarError('アカウントを追加に失敗しました。');
      setLoading(false);
      return;
    }
    appendClose();
  };

  const handleConfirm = async () => {
    if (lpAccountName === '') {
      setFromError(true);
      return;
    }
    if (passwordInput === '') {
      setPasswordError(true);
      return;
    }
    try {
      const accountService = ServiceManager.getAccountService();
      await accountService?.submitPassword(passwordInput);
      const privateKey = await ServiceManager.getAccountService()?.exportAccount(
        passwordInput as string,
        wallet.address as string
      );
      const owner = new ethers.Wallet(privateKey as string, network.nriProvider);
      createLpAccount(owner);
      setPasswordInput('');
      setOpen(false);
    } catch (error) {
      setPasswordError(true);
    }
  };

  const handleConfirm1 = async () => {
    if (lpDetailName === '') {
      setFromError(true);
      return;
    }
    if (passwordInput === '') {
      setPasswordError(true);
      return;
    }
    try {
      const accountService = ServiceManager.getAccountService();
      await accountService?.submitPassword(passwordInput);
      const privateKey = await ServiceManager.getAccountService()?.exportAccount(
        passwordInput as string,
        wallet.address as string
      );
      const owner = new ethers.Wallet(privateKey as string, network.nriProvider);
      updateLpAccount(owner);
      setPasswordInput('');
      setOpen(false);
    } catch (error) {
      setPasswordError(true);
    }
  };

  const privateOpen = () => {
    if (lpAccountName === '') {
      setFromError(true);
      return;
    }
    setOpen(true);
  };

  const privateDetailOpen = () => {
    if (lpDetailName === '') {
      setFromError(true);
      return;
    }
    setOpen(true);
  };

  if (createOpenFlag) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title="新規" />
                <CardContent>
                  <span style={{ float: 'left' }}>名前</span>
                  <div style={{ clear: 'both' }} />
                  <TextField
                    id="name"
                    fullWidth
                    value={lpAccountName}
                    onChange={handleChange}
                    style={{ marginTop: '5px', marginBottom: 10, width: '92%' }}
                    required
                    error={formError}
                    helperText={formError ? '名前を入力してください！' : ''}
                  />
                  <span style={{ float: 'left' }}>申請者アドレス入力</span><span style={{ marginLeft: 10, fontSize: 'smaller', color: '#1976d2', cursor: 'pointer', float: 'left', marginTop: 3 }} onClick={() => addApplyInput()} >追加</span>
                  <div style={{ clear: 'both' }} />
                  {
                    applyInput.map((item: any) => (
                      <div key={item.id} >
                        <TextField
                          id="applyAddress"
                          name={item.id}
                          fullWidth
                          value={item.address}
                          onChange={handleChange}
                          style={{ marginTop: '5px', marginBottom: 10, width: '92%' }}
                        /><span style={{ marginTop: 23, fontSize: 'smaller', color: '#1976d2', cursor: 'pointer', float: 'right' }} onClick={() => deleteApplyInput(item)} >削除</span>
                      </div>
                    ))
                  }
                  <span style={{ float: 'left' }}>承認者アドレス入力</span><span style={{ marginLeft: 10, fontSize: 'smaller', color: '#1976d2', cursor: 'pointer', float: 'left', marginTop: 3 }} onClick={() => addVerifyInput()} >追加</span>
                  <div style={{ clear: 'both' }} />
                  {
                    verifyInput.map((item: any) => (
                      <div key={item.id} >
                        <TextField
                          id="verifyAddress"
                          name={item.id}
                          fullWidth
                          value={item.address}
                          onChange={handleChange}
                          style={{ marginTop: '5px', marginBottom: 10, width: '92%' }}
                        /><span style={{ marginTop: 23, fontSize: 'smaller', color: '#1976d2', cursor: 'pointer', float: 'right' }} onClick={() => deleteVerifyInput(item)} >削除</span>
                      </div>
                    ))
                  }
                  <span style={{ float: 'left' }}>承認者の数</span>
                  <div style={{ clear: 'both' }} />
                  <TextField
                    id="requiredNumber"
                    fullWidth
                    value={requiredInput}
                    onChange={handleChange}
                    style={{ marginTop: '5px', marginBottom: 10, width: '92%' }}
                  />
                </CardContent>
                <CardActions>
                  <div style={{ width: '100%', textAlign: 'center', }}>
                    <Button onClick={() => { createClose() }} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '23%' }} >取 消</Button>
                    <LoadingButton loading={loading} style={{ width: '23%', marginLeft: 50 }} onClick={privateOpen} variant="outlined" autoFocus>
                      作 成 (管理者)
                    </LoadingButton>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>
        <Snackbar open={!!snackbarError} autoHideDuration={6000} onClose={closeSnackbarError}>
          <Alert severity="error" onClose={closeSnackbarError} sx={{ width: '100%' }}>
            {snackbarError}
          </Alert>
        </Snackbar>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">パスワード</DialogTitle>
            <DialogContent>
              <TextField
                id="passwordInput"
                label="パスワード"
                fullWidth
                value={passwordInput}
                onChange={handleChangePassword}
                style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                required
                type="password"
                error={passwordError}
                helperText={passwordError ? 'パスワードが間違っています。' : ''}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>取 消</Button>
              <Button onClick={handleConfirm} autoFocus>
                確 認
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }

  if (appendOpenFlag) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title="追加" />
                <CardContent>
                  <span style={{ float: 'left' }}>名前</span>
                  <div style={{ clear: 'both' }} />
                  <TextField
                    id="lpName"
                    fullWidth
                    value={lpName}
                    onChange={handleChange1}
                    style={{ marginTop: '5px', marginBottom: 10 }}
                    required
                    error={formError}
                    helperText={formError ? '名前を入力してください！' : ''}
                  />
                  <span style={{ float: 'left' }}>アドレス</span>
                  <div style={{ clear: 'both' }} />
                  <TextField
                    id="lpAddress"
                    fullWidth
                    value={lpAddress}
                    onChange={handleChange1}
                    style={{ marginTop: '5px', marginBottom: 10 }}
                    required
                    error={formError1}
                    helperText={formError1 ? 'アドレスを入力してください！' : ''}
                  />
                </CardContent>
                <CardActions>
                  <div style={{ width: '100%', textAlign: 'center', }}>
                    <Button onClick={() => { appendClose() }} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '23%' }} >取 消</Button>
                    <LoadingButton loading={loading} style={{ width: '23%', marginLeft: 50 }} onClick={addLpAccount} variant="outlined" autoFocus>
                      保 存
                    </LoadingButton>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>
        <Snackbar open={!!snackbarError} autoHideDuration={6000} onClose={closeSnackbarError}>
          <Alert severity="error" onClose={closeSnackbarError} sx={{ width: '100%' }}>
            {snackbarError}
          </Alert>
        </Snackbar>
      </>
    );
  }

  if (detailOpenFlag) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title="詳細" />
                <CardContent>
                  <span style={{ float: 'left' }}>名前</span>
                  <div style={{ clear: 'both' }} />
                  <TextField
                    id="lpDetailName"
                    fullWidth
                    value={lpDetailName}
                    onChange={handleChange2}
                    style={{ marginTop: '5px', marginBottom: 10, width: '92%' }}
                    required
                    error={formError}
                    helperText={formError ? '名前を入力してください！' : ''}
                  />
                  <span style={{ float: 'left' }}>アドレス</span>
                  <div style={{ clear: 'both' }} />
                  <TextField
                    id="lpDetailAddress"
                    fullWidth
                    value={lpDetailAddress}
                    onChange={handleChange2}
                    style={{ marginTop: '5px', marginBottom: 10, width: '92%' }}
                    InputProps={{
                      readOnly: true,
                    }}
                    required
                  />
                  <span style={{ float: 'left' }}>申請者アドレス入力</span><span style={{ marginLeft: 10, fontSize: 'smaller', color: '#1976d2', cursor: 'pointer', float: 'left', marginTop: 3 }} onClick={() => addApplyInput()} >追加</span>
                  <div style={{ clear: 'both' }} />
                  {
                    applyInput.map((item: any) => (
                      <div key={item.id} >
                        <TextField
                          id="applyAddress"
                          name={item.id}
                          fullWidth
                          value={item.address}
                          onChange={handleChange2}
                          style={{ marginTop: '5px', marginBottom: 10, width: '92%' }}
                        /><span style={{ marginTop: 23, fontSize: 'smaller', color: '#1976d2', cursor: 'pointer', float: 'right' }} onClick={() => deleteApplyInput(item)} >削除</span>
                      </div>
                    ))
                  }
                  <span style={{ float: 'left' }}>承認者アドレス入力</span><span style={{ marginLeft: 10, fontSize: 'smaller', color: '#1976d2', cursor: 'pointer', float: 'left', marginTop: 3 }} onClick={() => addVerifyInput()} >追加</span>
                  <div style={{ clear: 'both' }} />
                  {
                    verifyInput.map((item: any) => (
                      <div key={item.id} >
                        <TextField
                          id="verifyAddress"
                          name={item.id}
                          fullWidth
                          value={item.address}
                          onChange={handleChange2}
                          style={{ marginTop: '5px', marginBottom: 10, width: '92%' }}
                        /><span style={{ marginTop: 23, fontSize: 'smaller', color: '#1976d2', cursor: 'pointer', float: 'right' }} onClick={() => deleteVerifyInput(item)} >削除</span>
                      </div>
                    ))
                  }
                  <span style={{ float: 'left' }}>承認者の数</span>
                  <div style={{ clear: 'both' }} />
                  <TextField
                    id="requiredDetailNumber"
                    fullWidth
                    value={requiredDetailInput}
                    onChange={handleChange2}
                    style={{ marginTop: '5px', marginBottom: 10, width: '92%' }}
                  />
                </CardContent>
                <CardActions>
                  <div style={{ width: '100%', textAlign: 'center', }}>
                    <Button onClick={() => { editLpAccountClose() }} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '23%' }} >取 消</Button>
                    <LoadingButton loading={loading} style={{ width: '23%', marginLeft: 50 }} onClick={privateDetailOpen} variant="outlined" autoFocus>
                      保 存 (管理者)
                    </LoadingButton>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>
        <Snackbar open={!!snackbarError} autoHideDuration={6000} onClose={closeSnackbarError}>
          <Alert severity="error" onClose={closeSnackbarError} sx={{ width: '100%' }}>
            {snackbarError}
          </Alert>
        </Snackbar>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">パスワード</DialogTitle>
            <DialogContent>
              <TextField
                id="passwordInput"
                label="パスワード"
                fullWidth
                value={passwordInput}
                onChange={handleChangePassword}
                style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                required
                type="password"
                error={passwordError}
                helperText={passwordError ? 'パスワードが間違っています。' : ''}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>取 消</Button>
              <Button onClick={handleConfirm1} autoFocus>
                確 認
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }

  return (
    <>
      <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader title="アカウント" />
              <CardContent>
                {
                  lpAccountList?.map((item: any) => (
                    <div key={item.address} style={{ marginTop: '8px', height: 50, width: '100%', border: '1px solid #cbcbcb' }} >
                      <div style={{ float: 'left', fontSize: '20px', marginLeft: 15, borderRadius: '5px', marginTop: 8 }}>{item.name}</div>
                      <span onClick={() => { deleteLpAccount(item) }} style={{ float: 'right', marginTop: '3%', marginRight: '3%', fontSize: 10, color: '#1976d2', cursor: 'pointer' }} >削除</span>
                      <span onClick={() => { editLpAccount(item) }} style={{ float: 'right', marginTop: '3%', marginRight: '1%', fontSize: 10, color: '#1976d2', cursor: 'pointer' }} >詳細</span>
                    </div>
                  ))
                }
              </CardContent>
              <CardActions>
                <div style={{ width: '100%', textAlign: 'center', }}>
                  <Button onClick={() => { createOpen() }} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '22%' }} >新 規 (管理者)</Button>
                  <Button onClick={() => { appendOpen() }} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '22%', marginLeft: 50 }} >
                    追 加
                  </Button>
                </div>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
      </Container>
      <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
          {snackbar}
        </Alert>
      </Snackbar>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">パスワード</DialogTitle>
          <DialogContent>
            <TextField
              id="passwordInput"
              label="パスワード"
              fullWidth
              value={passwordInput}
              onChange={handleChangePassword}
              style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
              required
              type="password"
              error={passwordError}
              helperText={passwordError ? 'パスワードが間違っています。' : ''}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>取 消</Button>
            <Button onClick={handleConfirm} autoFocus>
              確 認
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default PageLpAccount;
