import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import { SideMenuLayout } from './layout/sideMenuLayout';
import { MenuLayout } from './layout/menuLayout';
import { PageActivity } from './pages/activity';
import { PageDid } from './pages/did';
import { PageCallbackIssuance, PageInitiateIssuance } from './pages/issuance';
import { PageQr } from './pages/qr';
import { PageSettings } from './pages/settings';
import { PageSiop } from './pages/siop';
import { PageTop } from './pages/top';
import { PageVc } from './pages/vc';
import { PageNetwork } from './pages/network';
import { PageWallet } from './pages/wallet';
import { PageLpAccount } from './pages/lpAccount';
import { PageToken } from './pages/token';
import { PageTransActivity } from './pages/transActivity';
import { PageAfTansfer } from './pages/afTansfer';
import { ResidentContext, useResidentContext } from './contexts/ResidentContext';

import { persistor, store } from './store';

function RootApp() {
  const residentValue = useResidentContext();
  return (
    <BrowserRouter>
      <ResidentContext.Provider value={residentValue}>
        <Routes>
          <Route element={<MenuLayout />}>
            <Route path="network" element={<PageNetwork />} />
            <Route path="wallet" element={<PageWallet />} />
            <Route path="lpAccount" element={<PageLpAccount />} />
            <Route path="token" element={<PageToken />} />
            <Route path="afTansfer" element={<PageAfTansfer />} />
            <Route path="transactivity" element={<PageTransActivity />} />
          </Route>
          <Route element={<SideMenuLayout />}>
            <Route index element={<PageTop />} />
            <Route path="qr" element={<PageQr />} />
            <Route path="did" element={<PageDid />} />
            <Route path="vc" element={<PageVc />} />
            <Route path="activity" element={<PageActivity />} />
            <Route path="initiate_issuance" element={<PageInitiateIssuance />} />
            <Route path="cb_issuance/:ot/" element={<PageCallbackIssuance />} />
            <Route path="oidc_siop" element={<PageSiop />} />

            <Route path="settings" element={<PageSettings />} />

          </Route>

        </Routes>
      </ResidentContext.Provider>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} >
        <RootApp />
      </PersistGate>
    </Provider>
  );
}

export default App;
