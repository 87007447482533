/* eslint-disable no-alert */
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  MenuItem
} from '@mui/material';
import { CorporationManager } from 'corporation-core-sdk';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
// import dayjs from 'dayjs';
import Pagination from '@mui/material/Pagination';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
// import copy from 'copy-to-clipboard';
import { ethers } from 'ethers';
import { useContextSet } from '../helpers/common';
import { AfTansferModel } from '../dexie';
import { AfTansfer } from '../helpers/afTansfer';
import ServiceManager from '../helpers/ServiceManager';

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export function PageAfTansfer() {
  const { residentContext } = useContextSet();
  const { wallet, network, lpAccount } = residentContext;
  const [snackbar, setSnackbar] = useState<string>();
  const [snackbarError, setSnackbarError] = useState<string>();
  const [activityList, setActivityList] = useState<AfTansferModel[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [conut, setCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [noLimitsOpen, setNoLimitsOpen] = useState(false);
  const [refuseOpen, setRefuseOpen] = useState(false);
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [recognizeOpen, setRecognizeOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [activity, setActivity] = useState<AfTansferModel>();
  const [transValue, setTransValue] = useState<number>(0);
  const [selectAddress, setSelectAddress] = React.useState('');
  const [lpAccountList, setLpAccpuntList] = useState([]);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  const selectNumberInfoByAddress = async (lpAddress: string, pages: number) => {
    if (lpAccount && network && wallet) {
      if (lpAccount.afTansfer) {
        const list = await lpAccount.afTansfer.getEventList(network, lpAddress, pages, pageSize);
        const counts = await lpAccount.afTansfer.getEventListAll(network, lpAddress)
        if ((counts % pageSize) === 0) {
          setCount(counts / pageSize);
        } else {
          setCount(Math.ceil(counts / pageSize));
        }
        setActivityList(list);
      }
    }
  };

  const filterList = (arr: any, address: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newList = arr.reduce((pre, cur) => pre.some(item => item[address] === cur[address]) ? pre : [...pre, cur], [])
    return newList;
  }

  const scanNumber = async () => {
    if (wallet && network && lpAccount) {
      const list: any[] = [];
      lpAccount.walletList?.forEach((item: any) => {
        const lpAccountInfo = { name: '', address: '', permits: [], confirmers: [], required: '', walletList: [] }
        lpAccountInfo.name = item.name.concat(' (法人)');
        lpAccountInfo.address = item.address;
        lpAccountInfo.permits = item.permits;
        lpAccountInfo.confirmers = item.confirmers;
        lpAccountInfo.required = item.required;
        lpAccountInfo.walletList = item.walletList;
        list.push(lpAccountInfo);

      })

      const newList = filterList(list, 'address');
      if (newList.length !== 0) {
        // 查询申请总数量
        newList.forEach(async (item: any) => {
          const CorporationMgr = new CorporationManager();
          await CorporationMgr.connect(item.address, network.rpcUrl);
          const number = await CorporationMgr.getTransactionCount(7, false);
          const scanRes = await lpAccount.afTansfer?.scan(CorporationMgr, Number(number.toString()), network, wallet.address, item.address);
          if (scanRes?.isUpdate) {
            let selectAddresss = selectAddress;
            if (!selectAddresss) {
              selectAddresss = lpAccount.walletList[0].address;
            }
            const startCount = (page - 1) * pageSize;
            selectNumberInfoByAddress(selectAddresss, startCount);
          }
        })
      }
    }
  };

  const batchSetting = async () => {
    if (wallet && network && lpAccount?.walletList) {
      let selectAddresss = selectAddress;
      if (!selectAddresss) {
        selectAddresss = lpAccount.walletList[0].address;
      }
      const list = await lpAccount.afTansfer?.getHistoryList(network, selectAddresss) || [];
      list.forEach(async (item: any) => {
        const CorporationMgr = new CorporationManager();
        await CorporationMgr.connect(selectAddress, network.rpcUrl);
        const transaction = await CorporationMgr.getTransaction(wallet.address, Number(item.transactionId));
        if (transaction.status !== 1) {
          lpAccount.afTansfer?.add(item.transactionId, item.chainId, item.contractAddress, item.lpAddress, item.address, '', item.symbol, item.number, item.from, item.to, item.value, true, transaction.status, 1);
          lpAccount.afTansfer?.setScannedNumber((lpAccount.afTansfer.getScannedNumber() - 1), item.lpAddress, network);
        }
      })
      const startCount = (page - 1) * pageSize;
      selectNumberInfoByAddress(selectAddresss, startCount);
    }
  };

  const getActivityInfo = async (lpAddress: string) => {
    if (wallet?.address && network) {
      lpAccount?.setWallet(network, lpAddress);
      const afTransfer = new AfTansfer([], "", 0);
      const list = await afTransfer.getEventList(network, lpAddress, 0, pageSize);
      setActivityList(list);
      const counts = await afTransfer.getEventListAll(network, lpAddress)
      if ((counts % pageSize) === 0) {
        setCount(counts / pageSize);
      } else {
        setCount(Math.ceil(counts / pageSize));
      }
    }
  };

  useEffect(() => {
    if (lpAccount) {
      const list: any[] = [];
      lpAccount.walletList?.forEach((item: any) => {
        if (network?.chainId === item.chainId) {
          const lpAccountInfo = { name: '', address: '', permits: [], confirmers: [], required: '', walletList: [] }
          lpAccountInfo.name = item.name.concat(' (法人)');
          lpAccountInfo.address = item.address;
          lpAccountInfo.permits = item.permits;
          lpAccountInfo.confirmers = item.confirmers;
          lpAccountInfo.required = item.required;
          lpAccountInfo.walletList = item.walletList;
          list.push(lpAccountInfo);
        }
      })
      if (list.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setLpAccpuntList(list);
        getActivityInfo(list[0].address)
        setSelectAddress(list[0].address);
      }

    }
  }, [wallet, lpAccount]);

  useEffect(() => {
    // scanNumber();// 注释解除后删除
    const InterVal = setInterval(() => {
      scanNumber();
      batchSetting();
    }, 5000)
    return () => {
      clearInterval(InterVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, lpAccount, selectAddress, page]);

  if (!network || !wallet || !lpAccount) {
    return <Container />;
  }

  const showSnackbar = (msg: string) => {
    setSnackbar(msg);
  };

  const showSnackbarError = (msg: string) => {
    setSnackbarError(msg);
  };

  const closeSnackbarError = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarError(undefined);
  };

  const closeSnackbar = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(undefined);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageSize(pageSize);
    setPage(value);
    const startCount = (value - 1) * pageSize;
    selectNumberInfoByAddress(selectAddress, startCount);
  };

  const openTransInfo = async (event: AfTansferModel) => {
    if (event.status === 1) {
      const CorporationMgr = new CorporationManager();
      await CorporationMgr.connect(selectAddress, network.rpcUrl);
      const transaction = await CorporationMgr.getTransaction(wallet.address, Number(event.transactionId));
      if (transaction.status !== 1) {
        lpAccount.afTansfer?.add(event.transactionId, event.chainId, event.contractAddress, event.lpAddress, event.address, '', event.symbol, event.number, event.from, event.to, event.value, true, transaction.status, 1);
        lpAccount.afTansfer?.setScannedNumber((lpAccount.afTansfer.getScannedNumber() - 1), event.lpAddress, network);
        event.status = transaction.status;
      }

    }
    let lp = { name: '', address: '', permits: [], confirmers: [], required: '' }
    for (let index = 0; index < lpAccountList.length; index += 1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (lpAccountList[index].address === event.lpAddress) {
        lp = lpAccountList[index];
      }
    }
    let ifConfirmer = false;
    for (let index = 0; index < lp.confirmers.length; index += 1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (wallet?.address === lp.confirmers[index].address) {
        ifConfirmer = true;
      }
    }
    let ifPermit = false;
    for (let index = 0; index < lp.permits.length; index += 1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (wallet?.address === lp.permits[index].address) {
        ifPermit = true;
      }
    }

    if (ifConfirmer) {
      setOpen(true);
    }
    if (!ifConfirmer && ifPermit) {
      setRefuseOpen(true);
    }
    if (!ifConfirmer && !ifPermit) {
      setNoLimitsOpen(true);
    }
    const values = Number(event.value);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const valueInt = parseInt(event.value, 10);
    if ((values - valueInt) > 0) {
      setTransValue(values)
    } else {
      setTransValue(valueInt)
    }
    setActivity(event);
  };

  const closeTransInfo = () => {
    setOpen(false);
    setActivity(undefined);
    setTransValue(0)
  };

  const closeRefuseTransInfo = () => {
    setRefuseOpen(false);
    setActivity(undefined);
    setTransValue(0)
  };

  const closeNoLimitsTransInfo = () => {
    setNoLimitsOpen(false);
    setActivity(undefined);
    setTransValue(0)
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const address = event.target.value as string;
    setSelectAddress(address);
    selectNumberInfoByAddress(address, 0);
    setPage(1);
  };


  const refuseModal = () => {
    setVerifyOpen(true)
    setOpen(false);
  };

  const refuseCloseModal = () => {
    setVerifyOpen(false)
    setOpen(true);
  };

  const recognizeModal = () => {
    setRecognizeOpen(true)
    setOpen(false);
  };

  const recognizeCloseModal = () => {
    setRecognizeOpen(false)
    setOpen(true);
  };

  const cancelModal = () => {
    setCancelOpen(true)
    setRefuseOpen(false);
  };

  const cancelCloseModal = () => {
    setCancelOpen(false)
    setRefuseOpen(true);
  };

  const openPassword = () => {
    setPasswordDialog(true);
  }

  const closePassword = () => {
    setPasswordDialog(false)
  }

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.id) {
      setPasswordInput(() => event.target.value);
      setPasswordError(false);
    }
  };

  const handleConfirm = async () => {
    if (passwordInput === '') {
      setPasswordError(true);
      return;
    }
    try {
      const accountService = ServiceManager.getAccountService();
      await accountService?.submitPassword(passwordInput);
      // const privateKeys = await accountService?.exportAccount(
      //   passwordInput as string,
      //   wallet.address as string
      // );
      // closePassword()
      // transfer(privateKeys);
      // setPasswordInput('');
    } catch (error) {
      // setPasswordError(true);
    }
    showSnackbar('545454545')
  };

  const handleAgreeConfirm = async () => {
    if (passwordInput === '') {
      setPasswordError(true);
      return;
    }
    try {
      if (activity) {
        const accountService = ServiceManager.getAccountService();
        await accountService?.submitPassword(passwordInput);
        closePassword();
        setLoading(true);
        const privateKey = await ServiceManager.getAccountService()?.exportAccount(
          passwordInput as string,
          wallet.address as string
        );
        const owner = new ethers.Wallet(privateKey as string, network.nriProvider);
        try {
          const CorporationMgr = new CorporationManager();
          await CorporationMgr.connect(selectAddress, network.rpcUrl);
          const transactionHash = await CorporationMgr.confirmTransaction(owner, Number(activity.transactionId));
          // sdk查询订单是否承认
          const isConfirmed = await CorporationMgr.isConfirmed(Number(activity.transactionId));
          if (isConfirmed) {
            lpAccount.afTansfer?.add(activity.transactionId, activity.chainId, activity.contractAddress, activity.lpAddress, activity.address, transactionHash.hash, activity.symbol, activity.number, activity.from, activity.to, activity.value, true, 0, 1);
            lpAccount.afTansfer?.setScannedNumber((lpAccount.afTansfer.getScannedNumber() - 1), activity.lpAddress, network);
          }
          if (!isConfirmed) {
            lpAccount.afTansfer?.add(activity.transactionId, activity.chainId, activity.contractAddress, activity.lpAddress, activity.address, transactionHash.hash, activity.symbol, activity.number, activity.from, activity.to, activity.value, true, 1, 1);
          }
          showSnackbar('送金の申請は承認されました。')
          setLoading(false);
          recognizeCloseModal();
          closeTransInfo();
          selectNumberInfoByAddress(selectAddress, 0);
          setPasswordInput('');

        } catch (error) {
          showSnackbarError('操作に失敗しました。')
        }

      }

    } catch (error) {
      setPasswordError(true);
    }

  };

  const handleRefuseConfirm = async () => {
    if (passwordInput === '') {
      setPasswordError(true);
      return;
    }
    try {
      if (activity) {
        const accountService = ServiceManager.getAccountService();
        await accountService?.submitPassword(passwordInput);
        closePassword();
        setLoading(true);
        const privateKey = await ServiceManager.getAccountService()?.exportAccount(
          passwordInput as string,
          wallet.address as string
        );
        const owner = new ethers.Wallet(privateKey as string, network.nriProvider);
        try {
          const CorporationMgr = new CorporationManager();
          await CorporationMgr.connect(selectAddress, network.rpcUrl);
          const transactionHash = await CorporationMgr.refuseTransaction(owner, Number(activity.transactionId));
          // sdk查询订单是否拒否
          const isRefused = await CorporationMgr.isRefused(Number(activity.transactionId));
          if (isRefused) {
            lpAccount.afTansfer?.add(activity.transactionId, activity.chainId, activity.contractAddress, activity.lpAddress, activity.address, transactionHash.hash, activity.symbol, activity.number, activity.from, activity.to, activity.value, true, 4, 1);
          }
          if (!isRefused) {
            lpAccount.afTansfer?.add(activity.transactionId, activity.chainId, activity.contractAddress, activity.lpAddress, activity.address, transactionHash.hash, activity.symbol, activity.number, activity.from, activity.to, activity.value, true, 1, 1);
          }
          showSnackbar('送金の申請は拒否されました。')
          setLoading(false);
          refuseCloseModal();
          closeTransInfo();
          selectNumberInfoByAddress(selectAddress, 0);
          setPasswordInput('');

        } catch (error) {
          showSnackbarError('操作に失敗しました。')
        }

      }
    } catch (error) {
      setPasswordError(true);
    }

  };

  const handleCancelConfirm = async () => {
    if (passwordInput === '') {
      setPasswordError(true);
      return;
    }
    try {
      if (activity) {
        const accountService = ServiceManager.getAccountService();
        await accountService?.submitPassword(passwordInput);
        closePassword();
        setLoading(true);
        const privateKey = await ServiceManager.getAccountService()?.exportAccount(
          passwordInput as string,
          wallet.address as string
        );
        const owner = new ethers.Wallet(privateKey as string, network.nriProvider);
        try {
          const CorporationMgr = new CorporationManager();
          await CorporationMgr.connect(selectAddress, network.rpcUrl);
          const transactionHash = await CorporationMgr.cancelTransaction(owner, Number(activity.transactionId));
          lpAccount.afTansfer?.add(activity.transactionId, activity.chainId, activity.contractAddress, activity.lpAddress, activity.address, transactionHash.hash, activity.symbol, activity.number, activity.from, activity.to, activity.value, true, 2, 1);
          showSnackbar('送金の申請はキャンセルされました。')
          setLoading(false);
          cancelCloseModal();
          closeRefuseTransInfo();
          selectNumberInfoByAddress(selectAddress, 0);
          setPasswordInput('');

        } catch (error) {
          showSnackbarError('操作に失敗しました。')
        }

      }
    } catch (error) {
      setPasswordError(true);
    }

  };

  // 送金申請詳細
  if (open) {
    return (
      <>
        <Container maxWidth="md" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title='送金申請詳細' />
                <div style={{ color: 'rgb(25 118 210)', float: 'right', marginTop: '-50px', cursor: 'pointer' }}><Button onClick={closeTransInfo}>戻 る</Button></div>
                <CardContent>
                  <div>
                    <div style={{ float: 'left' }}>
                      <span style={{ color: '#24272a', fontWeight: 'bold' }}>ステータス</span>
                      <br />
                      {activity?.status === 1 ? <span style={{ color: '#DB0964' }}>確認待ち</span> : null}
                      {activity?.status === 0 ? <span style={{ color: 'green' }}>確認されました</span> : null}
                      {activity?.status === 2 ? <span style={{ color: '#9E9E9E' }}>キャンセルしました</span> : null}
                      {activity?.status === 4 ? <span style={{ color: '#9E9E9E' }}>拒否しました</span> : null}
                    </div>

                    {/* <div style={{ float: 'right' }}>
                      <a style={{ float: 'right', color: '#0376c9', fontSize: 15, cursor: 'pointer' }} onClick={() => copyHash(activity?.transactionHash)}>トランザクションIDをコピー</a>
                      <br /> */}
                    {/* 删除时间戳 */}
                    {/* <a style={{ float: 'right', color: 'black', fontSize: 15 }}>{activity ? dayjs.unix(activity?.transTime).format('YYYY-MM-DD HH:mm:ss') : ''}</a> */}
                    {/* </div> */}
                  </div>
                  <div style={{ clear: 'both' }} />
                  <br />
                  <div>
                    <div>
                      <div style={{ color: '#24272a', fontWeight: 'bold' }}>移動元</div>
                      <div style={{ color: 'black', fontSize: 15 }}>{activity?.from}</div>
                    </div>
                    <br />
                    <div>
                      <div style={{ color: '#24272a', fontWeight: 'bold' }}>移動先</div>
                      <div style={{ color: 'black', fontSize: 15 }}>{activity?.to}</div>
                    </div>
                    <br />
                    <div>
                      <div style={{ color: '#24272a', fontWeight: 'bold', float: 'left' }}>金額</div>
                      <div style={{ float: 'right' }}>{transValue} {activity?.symbol}</div>
                    </div>
                    <div style={{ clear: 'both' }} />
                  </div>
                </CardContent>
                <CardActions>
                  {
                    activity?.status === 1 ?
                      <div style={{ width: '100%', textAlign: 'center', }}>
                        {
                          activity.isOperate === 1 ?
                            <div>
                              <Button disabled onClick={refuseModal} style={{ backgroundColor: '#9E9E9E', color: 'rgb(255 255 255)', width: '20%' }}>拒 否</Button>
                              <Button disabled onClick={recognizeModal} style={{ backgroundColor: '#9E9E9E', color: 'rgb(255 255 255)', width: '20%', marginLeft: 50 }}>承 認</Button>
                            </div>
                            :
                            <div>
                              <Button onClick={refuseModal} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%' }}>拒 否</Button>
                              <Button onClick={recognizeModal} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%', marginLeft: 50 }}>承 認</Button>
                            </div>
                        }
                      </div>
                      :
                      <div style={{ width: '100%', textAlign: 'center', }}>
                        <Button disabled style={{ backgroundColor: '#9E9E9E', color: 'rgb(255 255 255)', width: '20%' }}>拒 否</Button>
                        <Button disabled style={{ backgroundColor: '#9E9E9E', color: 'rgb(255 255 255)', width: '20%', marginLeft: 50 }}>承 認</Button>
                      </div>
                  }
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>
        <Snackbar open={!!snackbarError} autoHideDuration={6000} onClose={closeSnackbarError}>
          <Alert severity="error" onClose={closeSnackbarError} sx={{ width: '100%' }}>
            {snackbarError}
          </Alert>
        </Snackbar>
      </>
    );
  }

  // 送金申請詳細
  if (refuseOpen) {
    return (
      <>
        <Container maxWidth="md" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title='送金申請詳細' />
                <div style={{ color: 'rgb(25 118 210)', float: 'right', marginTop: '-50px', cursor: 'pointer' }}><Button onClick={closeRefuseTransInfo}>戻 る</Button></div>
                <CardContent>
                  <div>
                    <div style={{ float: 'left' }}>
                      <span style={{ color: '#24272a', fontWeight: 'bold' }}>ステータス</span>
                      <br />
                      {activity?.status === 1 ? <span style={{ color: '#DB0964' }}>確認待ち</span> : null}
                      {activity?.status === 0 ? <span style={{ color: 'green' }}>確認されました</span> : null}
                      {activity?.status === 2 ? <span style={{ color: '#9E9E9E' }}>キャンセルしました</span> : null}
                      {activity?.status === 4 ? <span style={{ color: '#9E9E9E' }}>拒否しました</span> : null}
                    </div>

                    {/* <div style={{ float: 'right' }}>
                      <a style={{ float: 'right', color: '#0376c9', fontSize: 15, cursor: 'pointer' }} onClick={() => copyHash(activity?.transactionHash)}>トランザクションIDをコピー</a>
                      <br /> */}
                    {/* 删除时间戳 */}
                    {/* <a style={{ float: 'right', color: 'black', fontSize: 15 }}>{activity ? dayjs.unix(activity?.transTime).format('YYYY-MM-DD HH:mm:ss') : ''}</a> */}
                    {/* </div> */}
                  </div>
                  <div style={{ clear: 'both' }} />
                  <br />
                  <div>
                    <div>
                      <div style={{ color: '#24272a', fontWeight: 'bold' }}>移動元</div>
                      <div style={{ color: 'black', fontSize: 15 }}>{activity?.from}</div>
                    </div>
                    <br />
                    <div>
                      <div style={{ color: '#24272a', fontWeight: 'bold' }}>移動先</div>
                      <div style={{ color: 'black', fontSize: 15 }}>{activity?.to}</div>
                    </div>
                    <br />
                    <div>
                      <div style={{ color: '#24272a', fontWeight: 'bold', float: 'left' }}>金額</div>
                      <div style={{ float: 'right' }}>{transValue} {activity?.symbol}</div>
                    </div>
                    <div style={{ clear: 'both' }} />
                  </div>
                </CardContent>
                <CardActions>
                  {
                    activity?.status === 1 ?
                      <div style={{ width: '100%', textAlign: 'center', }}>
                        <Button onClick={cancelModal} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%' }}>キャンセル</Button>
                      </div>
                      :
                      <div style={{ width: '100%', textAlign: 'center', }}>
                        <Button disabled onClick={cancelModal} style={{ backgroundColor: '#9E9E9E', color: 'rgb(255 255 255)', width: '20%' }}>キャンセル</Button>
                      </div>
                  }
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <div>
          <Dialog
            open={passwordDialog}
            onClose={closePassword}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">パスワード</DialogTitle>
            <DialogContent>
              <TextField
                id="passwordInput"
                label="パスワード"
                fullWidth
                value={passwordInput}
                onChange={handleChangePassword}
                style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                required
                type="password"
                error={passwordError}
                helperText={passwordError ? 'パスワードが間違っています。' : ''}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closePassword}>取 消</Button>
              <Button onClick={handleConfirm} autoFocus>
                確 認
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>
      </>
    );
  }

  // 送金申請詳細
  if (noLimitsOpen) {
    return (
      <>
        <Container maxWidth="md" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title='送金申請詳細' />
                <div style={{ color: 'rgb(25 118 210)', float: 'right', marginTop: '-50px', cursor: 'pointer' }}><Button onClick={closeNoLimitsTransInfo}>戻 る</Button></div>
                <CardContent>
                  <div>
                    <div style={{ float: 'left' }}>
                      <span style={{ color: '#24272a', fontWeight: 'bold' }}>ステータス</span>
                      <br />
                      <span style={{ color: '#DB0964' }}>確認待ち</span>
                    </div>

                    {/* <div style={{ float: 'right' }}>
                      <a style={{ float: 'right', color: '#0376c9', fontSize: 15, cursor: 'pointer' }} onClick={() => copyHash(activity?.transactionHash)}>トランザクションIDをコピー</a>
                      <br /> */}
                    {/* 删除时间戳 */}
                    {/* <a style={{ float: 'right', color: 'black', fontSize: 15 }}>{activity ? dayjs.unix(activity?.transTime).format('YYYY-MM-DD HH:mm:ss') : ''}</a> */}
                    {/* </div> */}
                  </div>
                  <div style={{ clear: 'both' }} />
                  <br />
                  <div>
                    <div>
                      <div style={{ color: '#24272a', fontWeight: 'bold' }}>移動元</div>
                      <div style={{ color: 'black', fontSize: 15 }}>{activity?.from}</div>
                    </div>
                    <br />
                    <div>
                      <div style={{ color: '#24272a', fontWeight: 'bold' }}>移動先</div>
                      <div style={{ color: 'black', fontSize: 15 }}>{activity?.to}</div>
                    </div>
                    <br />
                    <div>
                      <div style={{ color: '#24272a', fontWeight: 'bold', float: 'left' }}>金額</div>
                      <div style={{ float: 'right' }}>{transValue} {activity?.symbol}</div>
                    </div>
                    <div style={{ clear: 'both' }} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <div>
          <Dialog
            open={passwordDialog}
            onClose={closePassword}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">パスワード</DialogTitle>
            <DialogContent>
              <TextField
                id="passwordInput"
                label="パスワード"
                fullWidth
                value={passwordInput}
                onChange={handleChangePassword}
                style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                required
                type="password"
                error={passwordError}
                helperText={passwordError ? 'パスワードが間違っています。' : ''}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closePassword}>取 消</Button>
              <Button onClick={handleConfirm} autoFocus>
                確 認
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>
      </>
    );
  }

  // 送金拒否
  if (verifyOpen) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title='送金拒否' />
                <CardContent>
                  <div style={{ textAlign: 'center', margin: '30px auto' }}>
                    <div style={{ color: '#24272a', fontSize: 23 }}>送金を拒否しますか?</div>
                  </div>
                </CardContent>
                <CardActions>
                  <div style={{ width: '100%', textAlign: 'center', }}>
                    <Button onClick={refuseCloseModal} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%' }}>戻 る</Button>
                    <LoadingButton loading={loading} style={{ width: '20%', marginLeft: 50 }} onClick={openPassword} variant="outlined" autoFocus>
                      確 認
                    </LoadingButton>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <div>
          <Dialog
            open={passwordDialog}
            onClose={closePassword}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">パスワード</DialogTitle>
            <DialogContent>
              <TextField
                id="passwordInput"
                label="パスワード"
                fullWidth
                value={passwordInput}
                onChange={handleChangePassword}
                style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                required
                type="password"
                error={passwordError}
                helperText={passwordError ? 'パスワードが間違っています。' : ''}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closePassword}>取 消</Button>
              <Button onClick={handleRefuseConfirm} autoFocus>
                確 認
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>
      </>
    );
  }

  // 送金承認
  if (recognizeOpen) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title='送金承認' />
                <CardContent>
                  <div style={{ textAlign: 'center', margin: '30px auto' }}>
                    <div style={{ color: '#24272a', fontSize: 23 }}>送金を確認しますか?</div>
                  </div>
                </CardContent>
                <CardActions>
                  <div style={{ width: '100%', textAlign: 'center', }}>
                    <Button onClick={recognizeCloseModal} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%' }}>戻 る</Button>
                    <LoadingButton loading={loading} style={{ width: '20%', marginLeft: 50 }} onClick={openPassword} variant="outlined" autoFocus>
                      確 認
                    </LoadingButton>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <div>
          <Dialog
            open={passwordDialog}
            onClose={closePassword}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">パスワード</DialogTitle>
            <DialogContent>
              <TextField
                id="passwordInput"
                label="パスワード"
                fullWidth
                value={passwordInput}
                onChange={handleChangePassword}
                style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                required
                type="password"
                error={passwordError}
                helperText={passwordError ? 'パスワードが間違っています。' : ''}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closePassword}>取 消</Button>
              <Button onClick={handleAgreeConfirm} autoFocus>
                確 認
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>
      </>
    );
  }

  // 送金キャンセル
  if (cancelOpen) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title='送金キャンセル' />
                <CardContent>
                  <div style={{ textAlign: 'center', margin: '30px auto' }}>
                    <div style={{ color: '#24272a', fontSize: 23 }}>送金をキャンセルしますか?</div>
                  </div>
                </CardContent>
                <CardActions>
                  <div style={{ width: '100%', textAlign: 'center', }}>
                    <Button onClick={cancelCloseModal} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%' }}>戻 る</Button>
                    <LoadingButton loading={loading} style={{ width: '20%', marginLeft: 50 }} onClick={openPassword} variant="outlined" autoFocus>
                      確 認
                    </LoadingButton>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <div>
          <Dialog
            open={passwordDialog}
            onClose={closePassword}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">パスワード</DialogTitle>
            <DialogContent>
              <TextField
                id="passwordInput"
                label="パスワード"
                fullWidth
                value={passwordInput}
                onChange={handleChangePassword}
                style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                required
                type="password"
                error={passwordError}
                helperText={passwordError ? 'パスワードが間違っています。' : ''}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closePassword}>取 消</Button>
              <Button onClick={handleCancelConfirm} autoFocus>
                確 認
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>
      </>
    );
  }
 
  return (
    <>
      <Container maxWidth="md" sx={{ paddingX: '8px' }}>
        <Typography variant="h6" sx={{ marginTop: '16px' }}>
          送金申請一覧
        </Typography>
        <div style={{ color: 'rgb(25 118 210)', float: 'right', marginTop: '-32px', cursor: 'pointer', width: '22%' }}>
          {
            lpAccountList.length > 0 ?
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectAddress}
                style={{ height: 30, float: 'right', width: '100%' }}
                onChange={handleSelectChange}
              >
                {
                  lpAccountList.map((item: any) => (
                    <MenuItem key={item.address} value={item.address} >{item.name}</MenuItem>
                  ))
                }
              </Select>
              :
              null
          }
        </div>
        <div style={{ margin: '20px auto' }}><span>{selectAddress}</span></div>
        {activityList.map((row) => (
          <div key={row.transactionId} >
            {
              row.status === 0 ?
                <Card variant="outlined" sx={{ marginTop: '12px' }} style={{ borderLeft: '5px green solid' }}>
                  <CardContent>
                    <div style={{ float: 'right', fontSize: "large", cursor: 'pointer', marginTop: 18, color: '#1976d2' }} onClick={() => openTransInfo(row)}>申込詳細</div>
                    <div style={{ float: 'right', fontSize: "large", margin: '18px 30px' }}><span>{row.value} {row.symbol}</span></div>
                    <Typography fontSize={12}>
                      {/* 删除时间戳 */}
                      {/* {dayjs.unix(row.transTime).format('YYYY-MM-DD HH:mm:ss')} */}
                    </Typography>
                    <Typography fontSize={13} style={{ marginTop: 5 }}>
                      {'From：'}{row.from}
                    </Typography>
                    <Typography fontSize={13} style={{ marginTop: 5 }}>
                      {'To：'}{row.to}
                    </Typography>
                  </CardContent>
                </Card> : null
            }
            {
              row.status === 1 ?
                <Card variant="outlined" sx={{ marginTop: '12px' }} style={{ borderLeft: '5px #ffeb3b solid' }}>
                  <CardContent>
                    <div style={{ float: 'right', fontSize: "large", cursor: 'pointer', marginTop: 18, color: '#1976d2' }} onClick={() => openTransInfo(row)}>申込詳細</div>
                    <div style={{ float: 'right', fontSize: "large", margin: '18px 30px' }}><span>{row.value} {row.symbol}</span></div>
                    <Typography fontSize={12}>
                      {/* 删除时间戳 */}
                      {/* {dayjs.unix(row.transTime).format('YYYY-MM-DD HH:mm:ss')} */}
                    </Typography>
                    <Typography fontSize={13} style={{ marginTop: 5 }}>
                      {'From：'}{row.from}
                    </Typography>
                    <Typography fontSize={13} style={{ marginTop: 5 }}>
                      {'To：'}{row.to}
                    </Typography>
                  </CardContent>
                </Card> : null
            }
            {
              row.status === 2 ?
                <Card variant="outlined" sx={{ marginTop: '12px' }} style={{ borderLeft: '5px #9E9E9E solid' }}>
                  <CardContent>
                    <div style={{ float: 'right', fontSize: "large", cursor: 'pointer', marginTop: 18, color: '#1976d2' }} onClick={() => openTransInfo(row)}>申込詳細</div>
                    <div style={{ float: 'right', fontSize: "large", margin: '18px 30px' }}><span>{row.value} {row.symbol}</span></div>
                    <Typography fontSize={12}>
                      {/* 删除时间戳 */}
                      {/* {dayjs.unix(row.transTime).format('YYYY-MM-DD HH:mm:ss')} */}
                    </Typography>
                    <Typography fontSize={13} style={{ marginTop: 5 }}>
                      {'From：'}{row.from}
                    </Typography>
                    <Typography fontSize={13} style={{ marginTop: 5 }}>
                      {'To：'}{row.to}
                    </Typography>
                  </CardContent>
                </Card> : null
            }
            {
              row.status === 4 ?
                <Card variant="outlined" sx={{ marginTop: '12px' }} style={{ borderLeft: '5px #9E9E9E solid' }}>
                  <CardContent>
                    <div style={{ float: 'right', fontSize: "large", cursor: 'pointer', marginTop: 18, color: '#1976d2' }} onClick={() => openTransInfo(row)}>申込詳細</div>
                    <div style={{ float: 'right', fontSize: "large", margin: '18px 30px' }}><span>{row.value} {row.symbol}</span></div>
                    <Typography fontSize={12}>
                      {/* 删除时间戳 */}
                      {/* {dayjs.unix(row.transTime).format('YYYY-MM-DD HH:mm:ss')} */}
                    </Typography>
                    <Typography fontSize={13} style={{ marginTop: 5 }}>
                      {'From：'}{row.from}
                    </Typography>
                    <Typography fontSize={13} style={{ marginTop: 5 }}>
                      {'To：'}{row.to}
                    </Typography>
                  </CardContent>
                </Card> : null
            }

          </div>
        )
        )}
        {
          conut === 0 ? '' :
            <Stack spacing={2} style={{ marginTop: 10 }}>
              <Pagination page={page} count={conut} onChange={handleChange} color="primary" />
            </Stack>

        }
        <br />
        <br />
        <br />
      </Container>
      <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
          {snackbar}
        </Alert>
      </Snackbar>
    </>
  );
}

export default PageAfTansfer;
