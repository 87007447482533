/* eslint-disable no-console */
import { createContext, useCallback, useEffect, useState } from 'react';

import { Network } from '../helpers/network';
import { Wallets } from '../helpers/wallet';
import { Token } from '../helpers/token';
import { LpAccount } from '../helpers/lpAccount';

export type ResidentContextType = {
  network?: Network;
  setNetwork: (network: Network) => void;
  wallet?: Wallets;
  setWallet: (wallet: Wallets) => void;
  token?: Token;
  setToken: (token: Token) => void;
  lpAccount?: LpAccount;
  setLpAccount: (lpAccount: LpAccount) => void;
};

export const ResidentContext = createContext<ResidentContextType>({} as ResidentContextType);

export const useResidentContext = (): ResidentContextType => {
  const [network, setNetwork] = useState<Network>();
  const [wallet, setWallet] = useState<Wallets>();
  const [token, setToken] = useState<Token>();
  const [lpAccount, setLpAccount] = useState<LpAccount>();

  useEffect(() => {
    // 設定の読み込み
    Network.load().then((loadedNetwork) => {
      setNetwork(loadedNetwork);
      Wallets.load(loadedNetwork).then((loadedWallet) => {
        setWallet(loadedWallet);
      });
      Token.load().then((loadedToken) => {
        setToken(loadedToken);
      });
      LpAccount.load(loadedNetwork).then((loadedLpAccount) => {
        setLpAccount(loadedLpAccount);
      });
    });
  }, []);

  return {
    network,
    setNetwork: useCallback((current: Network): void => {
      setNetwork(current);
    }, []),
    wallet,
    setWallet: useCallback((current: Wallets): void => {
      setWallet(current);
    }, []),
    token,
    setToken: useCallback((current: Token): void => {
      setToken(current);
    }, []),
    lpAccount,
    setLpAccount: useCallback((current: LpAccount): void => {
      setLpAccount(current);
    }, []),
  };
};
