import {
  CardMembership as IconCardMembership,
  ChevronLeft as IconChevronLeft,
  ChevronRight as IconChevronRight,
  Home as IconHome,
  Menu as IconMenu,
  Key as IconKey,
  QrCode as IconQrCode,
  LocalActivity as IconLocalActivity,
  Settings as IconSettings,
  NetworkCheck as NetworkChecks,
  Wallet as IconWallet,
  AccountBalance as IconAccount,
  Token as IconToken,
  ScheduleSend as IconSend,
  LocalActivitySharp as IconActivity
} from '@mui/icons-material';
import {
  Backdrop,
  Box,
  CircularProgress,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  SelectChangeEvent,
  Select,
  MenuItem,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import pkg from '../../package.json';
import { NowLoadingContext, useNowLoadingContext } from '../contexts/NowLoadingContext';
import { ResidentContext, useResidentContext } from '../contexts/ResidentContext';
import { useContextSet } from '../helpers/common';
import { Wallets } from '../helpers/wallet';

const drawerWidth = 200;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const menulink = {
  textDecorationLine: 'none',
  color: '#444',
  fontWeight: 'bold',
};

export function MenuLayout() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { residentContext } = useContextSet();
  const { network } = residentContext;
  const [rpcUrl, setRpcUrl] = useState('');
  const [rpcList, setRpcList] = useState([{ rpcUrl: '', chainId: '', chainSymbol: '' }]);

  useEffect(() => {
    if (network) {
      setRpcUrl(network.rpcUrl);
      setRpcList(network.rpcList);
    }
  }, [network]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSelectChange = async (event: SelectChangeEvent<string>) => {
    setRpcUrl(() => event.target.value);
    if (network) {
      network.rpcUrl = event.target.value;
      setRpcList(network.rpcList)
      rpcList.forEach((item) => {
        if (item.rpcUrl === event.target.value) {
          network.chainId = item.chainId
          network.chainSymbol = item.chainSymbol
        }

      })
      await network.save();
      await network.load();
      residentContext.setWallet(await Wallets.load(network));

      window.location.reload()
    }
  };

  const nowLoadingValue = useNowLoadingContext();
  const residentValue = useResidentContext();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ minHeight: '40px' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <IconMenu />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {pkg.apptitle}
          </Typography>
          <Select
            labelId="select-transfer-lablel"
            id="select-transfer"
            value={rpcUrl}
            style={{ width: '35%', margin: '0px auto', border: '1px solid #c4c4c4', left: '-70px', borderRadius: 30, height: 50, backgroundColor: 'azure' }}
            onChange={handleSelectChange}
          >
            {
              rpcList.map((item: any) => (
                <MenuItem key={item.rpcUrl} value={item.rpcUrl}>{item.rpcUrl}</MenuItem>
              ))
            }
          </Select>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <IconChevronLeft /> : <IconChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          <NavLink to="/" style={menulink} onClick={handleDrawerClose}>
            <ListItem button>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <IconHome />
              </ListItemIcon>
              <ListItemText primary="トップ" />
            </ListItem>
          </NavLink>
          <NavLink to="/qr/" style={menulink} onClick={handleDrawerClose}>
            <ListItem button>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <IconQrCode />
              </ListItemIcon>
              <ListItemText primary="QR読み込み" />
            </ListItem>
          </NavLink>
          <NavLink to="/did/" style={menulink} onClick={handleDrawerClose}>
            <ListItem button>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <IconKey />
              </ListItemIcon>
              <ListItemText primary="DID詳細" />
            </ListItem>
          </NavLink>
          <NavLink to="/vc/" style={menulink} onClick={handleDrawerClose}>
            <ListItem button>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <IconCardMembership />
              </ListItemIcon>
              <ListItemText primary="資格情報" />
            </ListItem>
          </NavLink>
          <NavLink to="/activity/" style={menulink} onClick={handleDrawerClose}>
            <ListItem button>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <IconLocalActivity />
              </ListItemIcon>
              <ListItemText primary="アクティビティ" />
            </ListItem>
          </NavLink>
        </List>
        <List>
          <NavLink to="/settings/" style={menulink} onClick={handleDrawerClose}>
            <ListItem button>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <IconSettings />
              </ListItemIcon>
              <ListItemText primary="設定" />
            </ListItem>
          </NavLink>
        </List>
        <List>
          <Divider />
          <Divider />
          <NavLink to="/network/" style={menulink} onClick={handleDrawerClose}>
            <ListItem button>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <NetworkChecks />
              </ListItemIcon>
              <ListItemText primary="Network" />
            </ListItem>
          </NavLink>
          <NavLink to="/wallet/" style={menulink} onClick={handleDrawerClose}>
            <ListItem button>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <IconWallet />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </ListItem>
          </NavLink>
          <NavLink to="/lpAccount/" style={menulink} onClick={handleDrawerClose}>
            <ListItem button>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <IconAccount />
              </ListItemIcon>
              <ListItemText primary="法人Account" />
            </ListItem>
          </NavLink>
          <NavLink to="/token/" style={menulink} onClick={handleDrawerClose}>
            <ListItem button>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <IconToken />
              </ListItemIcon>
              <ListItemText primary="Token" />
            </ListItem>
          </NavLink>
          <NavLink to="/afTansfer/" style={menulink} onClick={handleDrawerClose}>
            <ListItem button>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <IconSend />
              </ListItemIcon>
              <ListItemText primary="送金申請一覧" />
            </ListItem>
          </NavLink>
          <NavLink to="/transactivity/" style={menulink} onClick={handleDrawerClose}>
            <ListItem button>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <IconActivity />
              </ListItemIcon>
              <ListItemText primary="Activity" />
            </ListItem>
          </NavLink>
        </List>
        <Divider />
        <List>
          <ListItemButton sx={{ fontSize: '14px', color: 'gray' }}>
            ver: {pkg.version}
            <br />
            build: {pkg.buildno}
          </ListItemButton>
        </List>
      </Drawer>
      <NowLoadingContext.Provider value={nowLoadingValue}>
        <ResidentContext.Provider value={residentValue}>
          <Main
            onClick={handleDrawerClose}
            sx={{ height: '100vh', width: '100%', paddingX: '8px' }}
          >
            <DrawerHeader sx={{ minHeight: '40px' }} />
            <Outlet />
          </Main>
          <Backdrop
            sx={{ color: '#fff', zIndex: (_theme) => _theme.zIndex.drawer + 1 }}
            open={nowLoadingValue.isNowLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </ResidentContext.Provider>
      </NowLoadingContext.Provider>
      <div className="SW-update-dialog" />
    </Box>
  );
}

export default MenuLayout;
