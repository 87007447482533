import { Dexie, Table } from 'dexie';
import {
  CredentialMetadata,
  DidObject,
  JwtObject,
  Secp256kPrivateJwk,
  ServerMetadataForIssuer,
  VerifiableCredentialJwt,
} from 'did-sdk';

// スキーマの修正がある場合、この値を変更する必要あり
const SCHEMA_VERSION = 6;

export type SettingsModel = {
  key: string;
  value: string | number | boolean;
};

/**
 * DidObjectからgetアクセサを除外し、idを追加
 */
export type DidModel = Omit<DidObject, 'did' | 'didShort' | 'didLong' | 'kid'> & {
  id: string;
};

export type PrivateKeyModel = {
  id: string;
  privateKey: Secp256kPrivateJwk;
};

export interface VcModel {
  id: number | undefined;
  vc: JwtObject<VerifiableCredentialJwt>;
  serverMetadata: ServerMetadataForIssuer;
  credential: CredentialMetadata;
}

export type CacheModel = {
  key: string;
  value: string;
};

export type ActivityModel = {
  id?: number;
  type: string;
  content: string;
  createdAt: string;
  options: Record<string, unknown>;
};

export type TransActivityModel = {
  id?: number;
  chainId: string;
  address: string;
  contractAddress: string;
  symbol: string;
  transactionHash: string;
  blockNumber: number;
  transTime: number;
  from: string;
  to: string;
  value: string;
  gasPrice: string,
  gasUsed: string
};

export type ScannedBlockModel = {
  chainId: string;
  address: string;
  contractAddress: string;
  block: number;
};

export type AfTansferModel = {
  transactionId: number;
  chainId: string;
  contractAddress: string;
  lpAddress: string;
  address: string;
  transactionHash: string;
  symbol: string;
  number: number;
  from: string;
  to: string;
  value: string;
  executed: boolean,
  status: number,
  isOperate:number
};

export type ScannedNumberModel = {
  lpAddress: string;
  chainId: string;
  number: number;
};

export type KeyValueModel = {
  key: string;
  value: string | number | boolean | object;
};

interface DidWalletDatabase extends Dexie {
  settings: Table<SettingsModel, string>;
  network: Table<SettingsModel, string>;
  did: Table<DidModel, string>;
  privatekey: Table<PrivateKeyModel, string>;
  vc: Table<VcModel, number>;
  cache: Table<CacheModel, string>;
  activity: Table<ActivityModel, number>;
  kv: Table<KeyValueModel, string>;
  wallet: Table<KeyValueModel, string>;
  lpAccount: Table<KeyValueModel, string>;
  token: Table<KeyValueModel, string>;
  transactivity: Table<TransActivityModel>;
  afTansfer: Table<AfTansferModel>;
  block: Table<KeyValueModel, string>;
  scanned: Table<ScannedBlockModel, string>;
  number: Table<ScannedNumberModel, string>;
}
export const dexieDb = new Dexie('didwallet-db') as DidWalletDatabase;

dexieDb.version(SCHEMA_VERSION).stores({
  settings: 'key',
  network: 'key',
  did: 'id',
  privatekey: 'id',
  vc: '++id',
  cache: 'key',
  activity: '++id',
  kv: 'key',
  wallet: 'key',
  lpAccount: 'key',
  token: 'key',
  transactivity: '&transactionHash, [chainId+from+contractAddress], [chainId+to+contractAddress], symbol, blockNumber, transTime, from, to, value',
  scanned: '&[address+chainId+contractAddress], block',
  afTansfer: '&[transactionId+chainId+contractAddress+lpAddress+address], [chainId+lpAddress+status],transactionHash, symbol, number, from, to, value, executed, status, isOperate',
  number: '&[lpAddress+chainId], number',
});
