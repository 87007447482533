/* eslint-disable no-console */
import { utils, ethers } from 'ethers';
import { TransActivityModel, dexieDb } from '../dexie';
import { Network } from './network';
import Abi from './ERC20.json';

export class TransActivity {
  private chainIds: string;

  private scannedBlock: number;

  private eventList: TransActivityModel[];

  constructor(eventList: TransActivityModel[], chainId: string, scannedBlock: number) {
    this.eventList = eventList;
    this.chainIds = chainId;
    this.scannedBlock = scannedBlock;
  }

  async getEventList(network: any, walletAddress: string, offset: number, pageSize: number) {
    const eventList = await dexieDb.transactivity
      .orderBy('transTime')
      .reverse()
      .filter(event => event.chainId === network.chainId && (event.from === walletAddress || event.to === walletAddress))
      .offset(offset)
      .limit(pageSize)
      .toArray();
    this.eventList = eventList;
    return eventList;
  }

  async getEventListByContract(network: any, walletAddress: string, offset: number, contractAddress: string, pageSize: number) {
    const eventList = await dexieDb.transactivity
      .orderBy('transTime')
      .reverse()
      .filter(event => event.chainId === network.chainId && (event.from === walletAddress || event.to === walletAddress) && event.contractAddress === contractAddress)
      .offset(offset)
      .limit(pageSize)
      .toArray();
    this.eventList = eventList;
    return eventList;
  }

  async getEventListAll(network: any, walletAddress: string) {
    const eventCount = await dexieDb.transactivity
      .orderBy('transTime')
      .reverse()
      .filter(event => event.chainId === network.chainId && (event.from === walletAddress || event.to === walletAddress))
      .toArray();
    this.chainIds = network.chainId;
    return eventCount;
  }

  async getEventListAllByContract(network: any, walletAddress: string, contractAddress: string) {
    const eventCount = await dexieDb.transactivity
      .filter(event => event.chainId === network.chainId && (event.from === walletAddress || event.to === walletAddress) && event.contractAddress === contractAddress)
      .count()
    this.chainIds = network.chainId;
    return eventCount;
  }

  async add(chainId: string, walletAddress: string, contractAddress: string, symbol: string, transactionHash: string, blockNumber: number, transTime: number, from: string, to: string, value: string, gasPrice: string, gasUsed: string) {
    const event: TransActivityModel | undefined = {
      chainId,
      address: walletAddress,
      contractAddress,
      symbol,
      transactionHash,
      blockNumber,
      transTime,
      from,
      to,
      value,
      gasPrice,
      gasUsed
    };
    this.chainIds = chainId;
    try {
      await dexieDb.transactivity.put(event);
    } catch (e) {
      console.error(e);
    }

  }

  getScannedBlock() {
    return this.scannedBlock;
  }

  async setScannedBlock(scannedBlock: number, walletAddress: string, contractAddress: string, network: Network) {
    const chainId = network?.chainId;
    if (chainId) {
      await dexieDb.scanned.put({
        chainId: chainId as string,
        address: walletAddress,
        contractAddress,
        block: scannedBlock,
      });
    }
    this.scannedBlock = scannedBlock;
  }

  async scan(contracts: any, latestBlock: number, network: Network, walletAddress: string, scanUnit = 1000) {
    const scannedBlock = (await dexieDb.scanned.get([walletAddress, this.chainIds, contracts.address]))?.block || 0;
    const fromBlock = scannedBlock;
    if (fromBlock < latestBlock) {
      const contract = new ethers.Contract(contracts.address, Abi.abi, network.nriProvider);
      const filter = {
        topics: [
          utils.id("Transfer(address,address,uint256)"),
          [utils.hexZeroPad(walletAddress, 32), undefined],
          [undefined, utils.hexZeroPad(walletAddress, 32)],
        ],
      };
      const toBlock = fromBlock + scanUnit > latestBlock ? latestBlock : fromBlock + scanUnit;
      let isUpdate = false;
      const tevntFrom = await contract.queryFilter(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filter,
        fromBlock,
        toBlock
      );
      const chainId = this.chainIds;
      if (tevntFrom) {
        if (tevntFrom.length > 0) {
          for (const event of tevntFrom) {
            // eslint-disable-next-line no-await-in-loop
            const block = await contract.provider.getBlock(event.blockNumber);
            const symbols = contracts.symbol;
            const transactionHashs = event.transactionHash;
            const blockNumbers = event.blockNumber;
            const transTime = block.timestamp;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const from = event.args[0];
            // 合约有大小写的情况
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const contractAddress = contracts.address;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const to = event.args[1];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const value = utils.formatEther(event.args[2]);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const valueInt = parseInt(value, 10);
            const txHash = event.transactionHash;
            // 使用保存的nriProvider
            // eslint-disable-next-line no-await-in-loop
            const tx = await network.nriProvider.getTransactionReceipt(txHash);
            let gasPrice = '0';
            if (tx.effectiveGasPrice) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              gasPrice = utils.formatUnits(tx.effectiveGasPrice, 'gwei');

            }
            const gasUsed = tx.gasUsed.toString();
            if (walletAddress === from || walletAddress === to) {
              if ((Number(value) - valueInt) > 0) {
                this.add(chainId, walletAddress, contractAddress, symbols, transactionHashs, blockNumbers, transTime, from, to, value, gasPrice, gasUsed);
              } else {
                this.add(chainId, walletAddress, contractAddress, symbols, transactionHashs, blockNumbers, transTime, from, to, valueInt.toString(), gasPrice, gasUsed);
              }
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isUpdate = true;
          }
        }
      }

      await this.setScannedBlock(toBlock, walletAddress, contracts.address, network);

      return {
        eventList: this.eventList,
        isUpdate,
        endBlock: toBlock,
        hasNext: toBlock < latestBlock,
      };
    }
    return {
      eventList: this.eventList,
      isUpdate: false,
      endBlock: scannedBlock,
      hasNext: false,
    };
  }

  static async load(walletAddress: string, network: Network, contractAddress: string) {
    console.debug(walletAddress, network, contractAddress)
    const activityMgr = new TransActivity([], '', 0);
    return activityMgr;
  }
}

export default TransActivity;
