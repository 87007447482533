// import { Wallet, ethers } from 'ethers';

import { dexieDb } from '../dexie';
import { Network } from './network';
import { TransActivity } from './transActivity';
import { AfTansfer } from './afTansfer';

export class LpAccount {
  public name: any;

  public address: any;

  public permits: any;

  public confirmers: any;

  public required: any;

  public chainId: any;

  public walletList: any;

  public transActivity?: TransActivity;

  public afTansfer?: AfTansfer;

  static KEYS = {
    NAME: 'NAME',
    ADDRESS: 'ADDRESS',
    PERMITS: 'PERMITS',
    CONFIRMERS: 'CONFIRMERS',
    REQUIRED: 'REQUIRED',
    CHAINID:'CHAINID',
    WALLETLIST: 'WALLETLIST',
  };

  async loadActivity(network: Network, contractAddress: string) {
    if (this.address) {
      this.transActivity = await TransActivity.load(this.address, network, contractAddress);
    }
  }

  async loadAfTansfer(network: Network) {
    if (this.address) {
      this.afTansfer = await AfTansfer.load(this.address, network);
    }
  }

  async setWallet(network: Network, contractAddress: string) {
    await this.loadActivity(network, contractAddress);
    await this.loadAfTansfer(network);
  }

  async load(network: Network) {
    const name = await dexieDb.lpAccount.get(LpAccount.KEYS.NAME);
    if (name) {
      this.name = name.value as string;
    }

    const address = await dexieDb.lpAccount.get(LpAccount.KEYS.ADDRESS);
    if (address) {
      this.address = address.value as string;
    }

    const permits = await dexieDb.lpAccount.get(LpAccount.KEYS.PERMITS);
    if (permits) {
      this.permits = permits.value as any;
    }

    const confirmers = await dexieDb.lpAccount.get(LpAccount.KEYS.CONFIRMERS);
    if (confirmers) {
      this.confirmers = confirmers.value as any;
    }

    const required = await dexieDb.lpAccount.get(LpAccount.KEYS.REQUIRED);
    if (required) {
      this.required = required.value as string;
    }

    const chainId = await dexieDb.lpAccount.get(LpAccount.KEYS.CHAINID);
    if (chainId) {
      this.chainId = chainId.value as string;
    }

    const walletList = await dexieDb.lpAccount.get(LpAccount.KEYS.WALLETLIST);
    if (walletList) {
      this.walletList = walletList.value as any;
    }
    this.loadActivity(network, '');
  }

  async save() {
    await dexieDb.lpAccount.put({ key: LpAccount.KEYS.NAME, value: this.name });
    await dexieDb.lpAccount.put({ key: LpAccount.KEYS.ADDRESS, value: this.address });
    await dexieDb.lpAccount.put({ key: LpAccount.KEYS.PERMITS, value: this.permits });
    await dexieDb.lpAccount.put({ key: LpAccount.KEYS.CONFIRMERS, value: this.confirmers });
    await dexieDb.lpAccount.put({ key: LpAccount.KEYS.REQUIRED, value: this.required });
    await dexieDb.lpAccount.put({ key: LpAccount.KEYS.CHAINID, value: this.chainId });
    await dexieDb.lpAccount.put({ key: LpAccount.KEYS.WALLETLIST, value: this.walletList });
  }

  static async load(network: Network) {
    const wallet = new LpAccount();
    await wallet.load(network);
    return wallet;
  }

  async clear() {
    await dexieDb.lpAccount.bulkDelete(Object.keys(LpAccount.KEYS.NAME));
    await dexieDb.lpAccount.bulkDelete(Object.keys(LpAccount.KEYS.ADDRESS));
    await dexieDb.lpAccount.bulkDelete(Object.keys(LpAccount.KEYS.PERMITS));
    await dexieDb.lpAccount.bulkDelete(Object.keys(LpAccount.KEYS.CONFIRMERS));
    await dexieDb.lpAccount.bulkDelete(Object.keys(LpAccount.KEYS.REQUIRED));
    await dexieDb.lpAccount.bulkDelete(Object.keys(LpAccount.KEYS.CHAINID));
    await dexieDb.lpAccount.bulkDelete(Object.keys(LpAccount.KEYS.WALLETLIST));
    await dexieDb.lpAccount.bulkDelete(Object.keys(LpAccount.KEYS));
    this.transActivity = undefined;
  }
}

export default LpAccount;
