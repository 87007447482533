/* eslint-disable no-alert */

/* eslint-disable no-console */
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
  MenuItem
} from '@mui/material';
import {
  ArrowRight as ArrowRights,
} from '@mui/icons-material';
import { CorporationManager } from 'corporation-core-sdk';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import Pagination from '@mui/material/Pagination';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import copy from 'copy-to-clipboard';
import { ethers, Wallet } from 'ethers';
import React, { ChangeEvent, SyntheticEvent, useEffect, useState, useCallback } from 'react';
import { ERC20, BigNumber } from 'crypto-core-sdk';
import Abi from '../helpers/ERC20.json';
import { ReactComponent as IncoHeader } from '../IncoHead.svg'
import { useContextSet } from '../helpers/common';
import { Token } from '../helpers/token';
import ServiceManager from '../helpers/ServiceManager';
import { TransActivityModel } from '../dexie';
import { TransActivity } from '../helpers/transActivity';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export function PageToken() {
  const { residentContext } = useContextSet();
  const { wallet, network, token, lpAccount } = residentContext;
  const [snackbar, setSnackbar] = useState<string>();
  const [snackbarError, setSnackbarError] = useState<string>();
  const [address, setAddress] = useState('');
  const [symbol, setSymbol] = useState('');
  const [decimal, setDecimal] = useState('18');
  const [toAddress, setToAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [contractInfoList, setContractInfoList] = useState([{ address: '', symbol: '', decimal: '18', rpcUrl: '', name: '' }]);
  const [tokenDetail, setTokenDetail] = useState({ address: '', symbol: '', decimal: '18', rpcUrl: '', name: '' });
  const [rpcBalance, setRpcBalance] = useState<number>(0);
  const [contractError, setContractError] = useState(false);
  const [symbolError, setSymbolError] = useState(false);
  const [decimalError, setDecimalError] = useState(false);
  const [toAddressError, setToAddressError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [open, setOpen] = useState(false);
  const [send, setSend] = useState(false);
  const [lpSend, setLpSend] = useState(false);
  const [sendInfo, setSendInfo] = useState(false);
  const [lpsendInfo, setLpSendInfo] = useState(false);
  const [tokenInfoOpen, seTokenInfoOpen] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [gasPrice, setGasPrice] = useState(0);
  const [gas, setGas] = useState('');
  const [activityList, setActivityList] = useState<TransActivityModel[]>([]);
  const [conut, setCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [activity, setActivity] = useState<TransActivityModel>();
  const [transValue, setTransValue] = useState<number>(0);
  const [lpAccountList, setLpAccpuntList] = useState([]);
  const [selectAddress, setSelectAddress] = React.useState('')
  const [selectName, setSelectName] = React.useState('')

  const getTokenList = () => {
    if (token) {
      const contractList = [{ address: '', symbol: '', decimal: '18', rpcUrl: '', name: '' }];
      contractList.pop()
      token.contractList?.forEach((item: any) => {
        if (item.rpcUrl === network?.rpcUrl) {
          contractList.push(item)
        }

      })
      setContractInfoList(contractList);
    }
  };

  const getLpAccpuntList = useCallback(async () => {
    if (lpAccount && wallet?.address) {
      const list = [];
      list.push({ name: '個人Account (個人)', address: wallet?.address });
      lpAccount.walletList?.forEach((item: any) => {
        if (network?.chainId === item.chainId) {
          const lpAccountInfo = { name: '', address: '', permits: [], confirmers: [], required: '', walletList: [] }
          lpAccountInfo.name = item.name.concat(' (法人)');
          lpAccountInfo.address = item.address;
          lpAccountInfo.permits = item.permits;
          lpAccountInfo.confirmers = item.confirmers;
          lpAccountInfo.required = item.required;
          lpAccountInfo.walletList = item.walletList;
          list.push(lpAccountInfo);
        }

      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setLpAccpuntList(list);
      setSelectAddress(wallet?.address);
      setSelectName(list[0].name);
    }
  }, [lpAccount]);

  const getActivityInfo = async (contractAddress: string) => {
    if (wallet && network) {
      const transActivity = new TransActivity([], "", 0);
      const list = await transActivity.getEventListByContract(network, wallet.address, 0, contractAddress, pageSize)
      setActivityList(list);
      const count = await transActivity.getEventListAll(network, wallet.address)
      const counts = count.length
      if ((counts % pageSize) === 0) {
        setCount(counts / pageSize);
      } else {
        setCount(Math.ceil(counts / pageSize));
      }
    }
  };

  useEffect(() => {
    getTokenList();
    getLpAccpuntList();
    setPageSize(pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, lpAccount?.walletList]);

  const openTokenModal = () => {
    setOpen(true);
  };

  const closeTokenModal = () => {
    setOpen(false);
    setAddress('');
    setSymbol('');
    setDecimal('');
  };

  const openSendModal = () => {
    seTokenInfoOpen(false);
    if (selectAddress === wallet?.address) {
      setSend(true);
    }

    if (selectAddress !== wallet?.address) {
      setLpSend(true);
    }
  };

  const closeSendModal = () => {
    setToAddress('');
    setAmount('');
    if (selectAddress === wallet?.address) {
      setSend(false);
    }

    if (selectAddress !== wallet?.address) {
      setLpSend(false);
    }
    seTokenInfoOpen(true);
  };

  const closeLpSendModal = () => {
    setToAddress('');
    setAmount('');
    setLpSend(false);
    seTokenInfoOpen(true);
  };

  const closeSendModal2 = () => {
    if (selectAddress === wallet?.address) {
      setSend(false);
    }

    if (selectAddress !== wallet?.address) {
      setLpSend(false);
    }
  };

  const openSendInfoModal = async () => {
    if (toAddress === '') {
      setToAddressError(true);
      return;
    }
    if (amount === '') {
      setAmountError(true);
      return;
    }
    closeSendModal2();
    if (network) {
      if (network) {
        // 使用保存的nriProvider
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        network.nriProvider.getGasPrice().then((s) => {
          setGasPrice(s.toNumber());
        })

      }
      const contracts = new ethers.Contract(tokenDetail.address, Abi.abi, network.nriProvider);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      contracts.connect(wallet?.address).estimateGas.transfer(toAddress, ethers.utils.parseEther(amount)).then((gass) => {
        const estimatedGasFee = gass.mul(gasPrice);
        setGas(ethers.utils.formatEther(estimatedGasFee));
      });
    }
    setSendInfo(true);
  };

  const openLpSendInfoModal = async () => {
    if (toAddress === '') {
      setToAddressError(true);
      return;
    }
    if (amount === '') {
      setAmountError(true);
      return;
    }
    closeSendModal2();
    setLpSendInfo(true);
  };

  const closeSendInfoModal = () => {
    setGas('')
    setSendInfo(false);
    setSend(true);
  };

  const closeLpSendInfoModal = () => {
    setLpSendInfo(false);
    setLpSend(true);
  };

  const selectBlockInfoByPage = async (pages: number) => {
    if (wallet && network) {
      const transActivity = new TransActivity([], network.chainId, 0);
      const list = await transActivity.getEventListByContract(network, wallet.address, pages, tokenDetail.address, pageSize)
      setActivityList(list);
      const count = await transActivity.getEventListAll(network, wallet.address)
      const counts = count.length
      if ((counts % pageSize) === 0) {
        setCount(counts / pageSize);
      } else {
        setCount(Math.ceil(counts / pageSize));
      }
    }
  };

  const scanBlockInfo = async (tokenInfo: any) => {
    if (wallet && network && tokenInfo) {
      const contract = new ethers.Contract(tokenInfo.address, Abi.abi, network.nriProvider);
      const block = await contract.provider.getBlockNumber();
      const transActivity = new TransActivity([], network.chainId, 0);
      const scanRes = await transActivity.scan(tokenInfo, block, network, wallet.address);
      if (scanRes?.isUpdate) {
        const list = await transActivity.getEventListByContract(network, wallet.address, 0, tokenInfo.address, pageSize)
        setActivityList(list);
      }
      if (scanRes?.hasNext) {
        setTimeout(scanBlockInfo, 250, tokenInfo);
      }
    }
  };

  const closeSendInfoModal1 = () => {
    setSendInfo(false);
    closeSendModal()
    scanBlockInfo(tokenDetail)
  };

  const closeLpSendInfoModal1 = () => {
    setLpSendInfo(false);
    setSendInfo(false);
    closeLpSendModal()
  };

  if (!network || !wallet) {
    return <Container />;
  }


  const openTokenInfo = async (tokenInfo: any) => {
    if (!wallet.address) {
      alert('Walletを作成をお願いします。')
      return;
    }
    getActivityInfo(tokenInfo.address);
    const tokenDecimal = parseInt(tokenInfo.decimal, 10);
    const contracts = new ethers.Contract(tokenInfo.address, Abi.abi, network.nriProvider);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    contracts?.balanceOf(selectAddress).then((erc20Balance) => {
      const values = Number(ethers.utils.formatUnits(erc20Balance.toString(), tokenDecimal));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const valueInt = parseInt(values, 10);
      if ((values - valueInt) > 0) {
        setRpcBalance(values);
      } else {
        setRpcBalance(valueInt);
      }
    });
    setTokenDetail(tokenInfo);
    seTokenInfoOpen(true)
    scanBlockInfo(tokenInfo)
  };

  const closeTokenInfo = () => {
    setRpcBalance(0);
    seTokenInfoOpen(false);
  };

  const showSnackbar = (msg: string) => {
    setSnackbar(msg);
  };

  const closeSnackbar = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(undefined);
  };

  const showSnackbarError = (msg: string) => {
    setSnackbarError(msg);
  };

  const closeSnackbarError = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarError(undefined);
  };

  const addressCheck = async (addresss: string) => {
    if (token) {
      token.contractList?.forEach((item: any) => {
        if (addresss === item.address) {
          showSnackbarError('Contract Addresssは既に存在します。')
          setAddressError(true);
        }
      })
      const contracts = new ethers.Contract(addresss, Abi.abi, network.nriProvider);
      const symbols = await contracts.symbol();
      setSymbol(symbols);
      const decimals = await contracts.decimals();
      setDecimal(decimals.toString());
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'address':
        setAddress(() => event.target.value);
        setContractError(false);
        setAddressError(false);
        addressCheck(event.target.value);
        break;
      case 'symbol':
        setSymbol(() => event.target.value);
        setSymbolError(false);
        break;
      case 'decimal':
        setDecimal(() => event.target.value);
        setDecimalError(false);
        break;
      default:
        break;
    }

  };

  const handleSendChange = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'toAddress':
        setToAddress(() => event.target.value);
        setToAddressError(false);
        break;
      case 'amount':
        setAmount(() => event.target.value);
        setAmountError(false)
        break;
      default:
        break;
    }

  };

  const add = async () => {
    if (addressError) {
      showSnackbarError('Contract Addresssは既に存在します。')
      return;
    }
    setLoading(true)
    if (token) {
      const list = token.contractList || [];
      const contractInfo = { address: '', symbol: '', decimal: '18', rpcUrl: '', name: '' };
      contractInfo.rpcUrl = network.rpcUrl;
      contractInfo.address = address;
      contractInfo.symbol = symbol;
      contractInfo.decimal = decimal;
      const contracts = new ethers.Contract(address, Abi.abi, network.nriProvider);
      const erc20Name = await contracts.name();
      contractInfo.name = erc20Name;
      list.push(contractInfo)
      token.contractList = list;
      await token.save();
      residentContext.setToken(await Token.load());
      if (wallet?.address) {
        const block = await network.nriProvider.getBlockNumber();
        const transActivity = new TransActivity([], "", 0);
        transActivity.setScannedBlock(block, wallet.address, address, network);

      }
      if (lpAccount?.walletList) {
        const block = await network.nriProvider.getBlockNumber();
        for (let index = 0; index < lpAccount?.walletList.length; index += 1) {
          const transActivity = new TransActivity([], "", 0);
          transActivity.setScannedBlock(block, lpAccount?.walletList[index].address, address, network);

        }
      }
      showSnackbar('設定を保存しました。');
      closeTokenModal()
    }
    setLoading(false)
  };

  const reduce = async () => {
    if (tokenDetail && token) {
      const list = [{ address: '', symbol: '', decimal: '18', rpcUrl: '', name: '' }];
      list.pop();
      token.contractList?.forEach((item: any) => {
        if (tokenDetail.address !== item.address) {
          list.push(item);
        }
      })
      token.contractList = list;
      await token.save();
      residentContext.setToken(await Token.load());
      showSnackbar('設定を保存しました。');
      closeTokenInfo()
    }
  };

  const addToken = () => {
    if (address === '') {
      setContractError(true);
      return;
    }
    if (symbol === '') {
      setSymbolError(true);
      return;
    }
    if (decimal === '') {
      setDecimalError(true);
      return;
    }
    add();
  };

  const transfer = async (privateKeys: any) => {
    setLoading(true);
    if (tokenDetail) {
      const contracts = new ERC20(tokenDetail.address, tokenDetail.rpcUrl);
      const amountInt = Number(amount) * (10 ** parseInt(tokenDetail.decimal, 10));
      const amounts = new BigNumber(amountInt);
      if (privateKeys) {
        try {
          const fromWallet = new ethers.Wallet(privateKeys, network.nriProvider);
          const transferInfo = await contracts.transfer(fromWallet, toAddress, amounts);
          await transferInfo.wait();
          setSnackbar('送金が完了しました。')
        } catch (error) {
          setSnackbarError('送金に失敗しました。');
        }
        const contractss = new ethers.Contract(tokenDetail.address, Abi.abi, network.nriProvider);
        const erc20Balance = await contractss?.balanceOf(wallet.address);
        const tokenDecimal = parseInt(tokenDetail.decimal, 10);
        const values = Number(ethers.utils.formatUnits(erc20Balance.toString(), tokenDecimal));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const valueInt = parseInt(values, 10);
        if ((values - valueInt) > 0) {
          setRpcBalance(values);
        } else {
          setRpcBalance(valueInt);
        }
      }
      closeSendInfoModal1();
    }
    setLoading(false)
  }

  const openPassword = () => {
    setPasswordDialog(true);
  }

  const closePassword = () => {
    setPasswordDialog(false)
  }

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.id) {
      setPasswordInput(() => event.target.value);
      setPasswordError(false);
    }
  };

  const handleConfirm = async () => {
    if (passwordInput === '') {
      setPasswordError(true);
      return;
    }
    try {
      const accountService = ServiceManager.getAccountService();
      await accountService?.submitPassword(passwordInput);
      const privateKeys = await accountService?.exportAccount(
        passwordInput as string,
        wallet.address as string
      );
      closePassword()
      transfer(privateKeys);
      setPasswordInput('');
    } catch (error) {
      setPasswordError(true);
    }
  };

  const submitTransaction = async (owner: Wallet) => {
    setLoading(true);
    if (tokenDetail) {
      const amountInt = Number(amount);
      try {
        // 调用sdk获取钱包
        const CorporationMgr = new CorporationManager();
        await CorporationMgr.connect(selectAddress, network.rpcUrl);
        await CorporationMgr.submitTransaction(owner, tokenDetail.address, toAddress, ethers.utils.parseEther(amountInt.toString()));
        setSnackbar('送金申請の提出に成功しました。')
      } catch (error) {
        setSnackbarError('送金申請の提出に失敗しました。');
      }
      const contractss = new ethers.Contract(tokenDetail.address, Abi.abi, network.nriProvider);
      const erc20Balance = await contractss?.balanceOf(selectAddress);
      const tokenDecimal = parseInt(tokenDetail.decimal, 10);
      const values = Number(ethers.utils.formatUnits(erc20Balance.toString(), tokenDecimal));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const valueInt = parseInt(values, 10);
      if ((values - valueInt) > 0) {
        setRpcBalance(values);
      } else {
        setRpcBalance(valueInt);
      }
      closeLpSendInfoModal1();
    }
    setLoading(false)
  }


  const handleConfirm1 = async () => {
    if (passwordInput === '') {
      setPasswordError(true);
      return;
    }
    try {
      const accountService = ServiceManager.getAccountService();
      await accountService?.submitPassword(passwordInput);
      const privateKeys = await accountService?.exportAccount(
        passwordInput as string,
        wallet.address as string
      );
      const owner = new ethers.Wallet(privateKeys as string, network.nriProvider);
      closePassword()
      submitTransaction(owner);
      setPasswordInput('');
    } catch (error) {
      setPasswordError(true);
    }
  };

  const openTransInfo = async (event: TransActivityModel) => {
    setOpen(true);
    const values = Number(event.value);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const valueInt = parseInt(event.value, 10);
    if ((values - valueInt) > 0) {
      setTransValue(values)
    } else {
      setTransValue(valueInt)
    }
    setActivity(event);
  };

  const handleChange1 = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    const startCount = (value - 1) * pageSize;
    selectBlockInfoByPage(startCount);
  };

  const closeTransInfo = () => {
    setOpen(false);
    setActivity(undefined);
    setTransValue(0);
  };

  const copyHash = (transactionHash: any) => {
    copy(transactionHash);
    showSnackbar('コピーに成功しました。');
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    if (lpAccount) {
      lpAccountList.forEach((item: any) => {
        if (event.target.value === item.address) {
          setSelectName(item.name);
        }
      })
    }
    setSelectAddress(event.target.value as string);
  };

  if (tokenInfoOpen) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2} style={{ marginBottom: 50 }}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader title={selectName} />
                <div style={{ color: 'rgb(25 118 210)', float: 'right', marginTop: '-50px', cursor: 'pointer' }}><Button onClick={reduce}>削 除</Button></div>
                <CardContent>
                  <div style={{ marginLeft: '45%' }}><IncoHeader style={{ width: 60, height: 60 }} /></div>
                  <div style={{ textAlign: 'center', fontSize: 'xx-large', height: 101 }}>{rpcBalance} {tokenDetail.symbol}</div>
                </CardContent>
                <Divider sx={{ marginX: '8px' }} />
                <CardActions>
                  <div style={{ width: '100%', textAlign: 'center', }}>
                    <Button onClick={closeTokenInfo} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%' }}>戻 る</Button>
                    <Button onClick={openSendModal} style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%', marginLeft: 50 }}>送 金</Button>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          {wallet.address === selectAddress ? activityList.map((row) => (
            <div key={row.transactionHash}>
              <Card variant="outlined" sx={{ marginTop: '8px', cursor: 'pointer' }} onClick={() => openTransInfo(row)}>
                <CardContent>
                  <div>{row.from === wallet.address ? <span style={{ fontSize: "large", color: 'green' }}>送金 {row.symbol}</span> : <span style={{ fontSize: "large", color: 'red' }}>受領 {row.symbol}</span>}{row.from === wallet.address ? <div style={{ float: 'right', fontSize: "large", color: 'green' }}><span>{'-'}{row.value} {row.symbol}</span></div> : <div style={{ float: 'right', fontSize: "large", color: 'red' }}><span>{'+'}{row.value} {row.symbol}</span></div>}</div>
                  <Typography fontSize={12}>
                    {dayjs.unix(row.transTime).format('YYYY-MM-DD HH:mm:ss')} · {row.from === wallet.address ? '移動先：' : '移動元：'}{row.from === wallet.address ? row.to : row.from}
                  </Typography>
                </CardContent>
              </Card>
              <Divider sx={{ marginY: '10px' }} />

            </div>
          )) : null
          }
          {
            (conut === 0 || wallet.address !== selectAddress) ? '' :
              <Stack spacing={2}>
                <Pagination page={page} count={conut} onChange={handleChange1} color="primary" />
              </Stack>
          }
          <div>
            <BootstrapDialog
              open={open}
              onClose={closeTransInfo}
              aria-labelledby="customized-dialog-title"
            >
              <BootstrapDialogTitle id="customized-dialog-title" onClose={closeTransInfo}>
                {activity?.from === wallet.address ? '送金 ' : '受領 '}{activity?.symbol}
              </BootstrapDialogTitle>
              <DialogContent>
                <div style={{ minWidth: 560 }}>
                  <div style={{ float: 'left' }}>
                    <span style={{ color: '#24272a', fontWeight: 'bold' }}>ステータス</span>
                    <br />
                    <span style={{ color: '#28a745' }}>確認されました</span>
                  </div>

                  <div style={{ float: 'right' }}>
                    <a style={{ float: 'right', color: '#0376c9', fontSize: 15, cursor: 'pointer' }} onClick={() => copyHash(activity?.transactionHash)}>トランザクションIDをコピー</a>
                    <br />
                    <a style={{ float: 'right', color: 'black', fontSize: 15 }}>{activity ? dayjs.unix(activity?.transTime).format('YYYY-MM-DD HH:mm:ss') : ''}</a>
                  </div>
                </div>
                <div style={{ clear: 'both' }} />
                <br />
                <div>
                  <div>
                    <div style={{ color: '#24272a', fontWeight: 'bold' }}>移動元</div>
                    <div style={{ color: 'black', fontSize: 15 }}>{activity?.from}</div>
                  </div>
                  <br />
                  <div>
                    <div style={{ color: '#24272a', fontWeight: 'bold' }}>移動先</div>
                    <div style={{ color: 'black', fontSize: 15 }}>{activity?.to}</div>
                  </div>
                  <br />
                  <div>
                    <div style={{ color: '#24272a', fontWeight: 'bold', float: 'left' }}>金額</div>
                    <div style={{ float: 'right' }}>{activity?.from === wallet.address ? '- ' : '+ '}{transValue} {activity?.symbol}</div>
                  </div>
                  <div style={{ clear: 'both' }} />
                  <br />
                  <div>
                    <div style={{ color: '#24272a', fontWeight: 'bold', float: 'left' }}>ガス使用量(単位)</div>
                    <div style={{ float: 'right' }}>{activity?.gasUsed}</div>
                  </div>
                  <div style={{ clear: 'both' }} />
                  <br />
                  <div>
                    <div style={{ color: '#24272a', fontWeight: 'bold', float: 'left' }}>ガス代</div>
                    <div style={{ float: 'right' }}>{activity?.gasPrice}</div>
                  </div>
                </div>

              </DialogContent>
            </BootstrapDialog>
          </div>
          <br />
          <br />
          <br />
        </Container>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>

        <Snackbar open={!!snackbarError} autoHideDuration={6000} onClose={closeSnackbarError}>
          <Alert severity="error" onClose={closeSnackbarError} sx={{ width: '100%' }}>
            {snackbarError}
          </Alert>
        </Snackbar>
      </>
    );
  }

  if (send) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader onClick={closeSendModal} title={`${tokenDetail.name}/送金内容の入力`} />
                <CardContent>
                  <TextField
                    id="toAddress"
                    label="送金先"
                    fullWidth
                    value={toAddress}
                    onChange={handleSendChange}
                    style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                    required
                    type='text'
                    error={toAddressError}
                    helperText={toAddressError ? "addressの入力をお願いします。" : ""}
                  />
                  <TextField
                    id="amount"
                    label="金额"
                    fullWidth
                    value={amount}
                    onChange={handleSendChange}
                    style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{tokenDetail.symbol}</InputAdornment>
                    }}
                    type='text'
                    error={amountError}
                    helperText={amountError ? "金额の入力をお願いします。" : ""}
                  />
                </CardContent>
                <Divider sx={{ marginX: '8px' }} />
                <CardActions>
                  <div style={{ width: '100%', textAlign: 'center', }}>
                    <Button style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%' }} onClick={closeSendModal}>戻 る</Button>
                    <Button style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%', marginLeft: 50 }} onClick={openSendInfoModal} autoFocus>
                      確 認
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>

        <Snackbar open={!!snackbarError} autoHideDuration={6000} onClose={closeSnackbarError}>
          <Alert severity="error" onClose={closeSnackbarError} sx={{ width: '100%' }}>
            {snackbarError}
          </Alert>
        </Snackbar>
      </>
    );
  }

  if (lpSend) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader onClick={closeLpSendModal} title={`${tokenDetail.name}/送金内容の入力`} />
                <CardContent>
                  <TextField
                    label="法人Account"
                    fullWidth
                    value={selectAddress}
                    style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                    required
                    type='text'
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    id="toAddress"
                    label="送金先"
                    fullWidth
                    value={toAddress}
                    onChange={handleSendChange}
                    style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                    required
                    type='text'
                    error={toAddressError}
                    helperText={toAddressError ? "addressの入力をお願いします。" : ""}
                  />
                  <TextField
                    id="amount"
                    label="金额"
                    fullWidth
                    value={amount}
                    onChange={handleSendChange}
                    style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{tokenDetail.symbol}</InputAdornment>
                    }}
                    type='text'
                    error={amountError}
                    helperText={amountError ? "金额の入力をお願いします。" : ""}
                  />
                </CardContent>
                <Divider sx={{ marginX: '8px' }} />
                <CardActions>
                  <div style={{ width: '100%', textAlign: 'center', }}>
                    <Button style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%' }} onClick={closeLpSendModal}>戻 る</Button>
                    <Button style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%', marginLeft: 50 }} onClick={openLpSendInfoModal} autoFocus>
                      確 認
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>

        <Snackbar open={!!snackbarError} autoHideDuration={6000} onClose={closeSnackbarError}>
          <Alert severity="error" onClose={closeSnackbarError} sx={{ width: '100%' }}>
            {snackbarError}
          </Alert>
        </Snackbar>
      </>
    );
  }

  if (sendInfo) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader onClick={closeSendModal} title={`${tokenDetail.name}/送金内容の確認`} />
                <CardContent>
                  <TextField
                    id="toAddress"
                    label="送金先"
                    fullWidth
                    value={toAddress}
                    style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                    InputProps={{
                      readOnly: true,
                    }}
                    type='text'
                  />
                  <TextField
                    id="amount"
                    label="金额"
                    fullWidth
                    value={amount}
                    style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{tokenDetail.symbol}</InputAdornment>,
                      readOnly: true,
                    }}
                    type='text'
                  />
                  <TextField
                    id="gas"
                    label="燃料 (推定)"
                    fullWidth
                    value={gas}
                    style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{network.chainSymbol}</InputAdornment>,
                      readOnly: true,
                    }}
                    type='text'
                  />
                </CardContent>
                <Divider sx={{ marginX: '8px' }} />
                <CardActions>
                  <div style={{ width: '100%', textAlign: 'center', }}>
                    <Button style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%' }} onClick={closeSendInfoModal}>取 消</Button>

                    <LoadingButton loading={loading} style={{ width: '20%', marginLeft: 50 }} onClick={openPassword} variant="outlined" autoFocus>
                      送 金
                    </LoadingButton>

                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>

        <Snackbar open={!!snackbarError} autoHideDuration={6000} onClose={closeSnackbarError}>
          <Alert severity="error" onClose={closeSnackbarError} sx={{ width: '100%' }}>
            {snackbarError}
          </Alert>
        </Snackbar>
        <div>
          <Dialog
            open={passwordDialog}
            onClose={closePassword}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">パスワード</DialogTitle>
            <DialogContent>
              <TextField
                id="passwordInput"
                label="パスワード"
                fullWidth
                value={passwordInput}
                onChange={handleChangePassword}
                style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                required
                type="password"
                error={passwordError}
                helperText={passwordError ? 'パスワードが間違っています。' : ''}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closePassword}>取 消</Button>
              <Button onClick={handleConfirm} autoFocus>
                確 認
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }

  if (lpsendInfo) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader onClick={closeLpSendInfoModal} title={`${tokenDetail.name}/送金内容の確認`} />
                <CardContent>
                  <TextField
                    label="法人Account"
                    fullWidth
                    value={selectAddress}
                    style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                    required
                    type='text'
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    id="toAddress"
                    label="送金先"
                    fullWidth
                    value={toAddress}
                    style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                    InputProps={{
                      readOnly: true,
                    }}
                    type='text'
                  />
                  <TextField
                    id="amount"
                    label="金额"
                    fullWidth
                    value={amount}
                    style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{tokenDetail.symbol}</InputAdornment>,
                      readOnly: true,
                    }}
                    type='text'
                  />
                </CardContent>
                <Divider sx={{ marginX: '8px' }} />
                <CardActions>
                  <div style={{ width: '100%', textAlign: 'center', }}>
                    <Button style={{ backgroundColor: '#1976D2', color: 'rgb(255 255 255)', width: '20%' }} onClick={closeLpSendInfoModal}>取 消</Button>

                    <LoadingButton loading={loading} style={{ width: '20%', marginLeft: 50 }} onClick={openPassword} variant="outlined" autoFocus>
                      送 金
                    </LoadingButton>

                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Container>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>

        <Snackbar open={!!snackbarError} autoHideDuration={6000} onClose={closeSnackbarError}>
          <Alert severity="error" onClose={closeSnackbarError} sx={{ width: '100%' }}>
            {snackbarError}
          </Alert>
        </Snackbar>
        <div>
          <Dialog
            open={passwordDialog}
            onClose={closePassword}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">パスワード</DialogTitle>
            <DialogContent>
              <TextField
                id="passwordInput"
                label="パスワード"
                fullWidth
                value={passwordInput}
                onChange={handleChangePassword}
                style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
                required
                type="password"
                error={passwordError}
                helperText={passwordError ? 'パスワードが間違っています。' : ''}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closePassword}>取 消</Button>
              <Button onClick={handleConfirm1} autoFocus>
                確 認
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }

  return (
    <>
      <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader title="トークン" />
              <div style={{ color: 'rgb(25 118 210)', float: 'right', marginTop: '-50px', cursor: 'pointer', marginRight: 15, width: '35%' }}>
                {
                  lpAccountList.length > 0 ?
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectAddress}
                      style={{ height: 30, float: 'right', width: '100%' }}
                      onChange={handleSelectChange}
                    >
                      {
                        lpAccountList.map((item: any) => (
                          <MenuItem key={item.address} value={item.address} >{item.name}</MenuItem>
                        ))
                      }
                    </Select>
                    :
                    null
                }

              </div>
              <CardContent>
                {
                  contractInfoList.map((item: any) => (
                    <div onClick={() => openTokenInfo(item)} key={item.address} style={{ marginTop: '8px', height: 50, width: '100%', border: '1px solid #cbcbcb', cursor: 'pointer' }} >
                      <div style={{ fontSize: '25px', marginLeft: 15, borderRadius: '5px', verticalAlign: 'text-top', width: '10%', float: 'left', marginTop: 6 }}>{item.name}</div>
                      <ArrowRights style={{ float: 'right', marginTop: 12, marginRight: '2%' }} />
                    </div>
                  ))
                }
              </CardContent>
              <Divider sx={{ marginX: '8px' }} />
              <CardActions>
                <Button size="small" onClick={openTokenModal}>
                  追加
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
      </Container>
      <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
          {snackbar}
        </Alert>
      </Snackbar>

      <Snackbar open={!!snackbarError} autoHideDuration={6000} onClose={closeSnackbarError}>
        <Alert severity="error" onClose={closeSnackbarError} sx={{ width: '100%' }}>
          {snackbarError}
        </Alert>
      </Snackbar>
      <div>
        <Dialog
          open={open}
          onClose={closeTokenModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            追加
          </DialogTitle>
          <DialogContent>
            <TextField
              id="address"
              label="Contract Address"
              fullWidth
              value={address}
              onChange={handleChange}
              style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
              required
              type='text'
              error={contractError}
              helperText={contractError ? "Contract Addressの入力をお願いします。" : ""}
            />
            <TextField
              id="symbol"
              label="Token Symbol"
              fullWidth
              value={symbol}
              onChange={handleChange}
              style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
              required
              type='text'
              error={symbolError}
              helperText={symbolError ? "Token Symbolの入力をお願いします。" : ""}
            />
            <TextField
              id="decimal"
              label="decimal"
              fullWidth
              value={decimal}
              onChange={handleChange}
              style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
              required
              type='text'
              error={decimalError}
              helperText={decimalError ? "decimalの入力をお願いします。" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeTokenModal}>取 消</Button>
            <LoadingButton loading={loading} style={{ border: '0px' }} onClick={addToken} variant="outlined" autoFocus>
              確 認
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default PageToken;
