/* eslint-disable no-alert */
import {
  Alert,
  Card,
  CardContent,
  Container,
  Divider,
  Snackbar,
  Typography,
  MenuItem
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Pagination from '@mui/material/Pagination';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import copy from 'copy-to-clipboard';
import { useContextSet } from '../helpers/common';
import { TransActivityModel } from '../dexie';
import { TransActivity } from '../helpers/transActivity';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export function PageTransActivity() {
  const { residentContext } = useContextSet();
  const { wallet, network, token, lpAccount } = residentContext;
  const [snackbar, setSnackbar] = useState<string>();
  const [activityList, setActivityList] = useState<TransActivityModel[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [conut, setCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [activity, setActivity] = useState<TransActivityModel>();
  const [transValue, setTransValue] = useState<number>(0);
  const [selectAddress, setSelectAddress] = React.useState('');
  const [lpAccountList, setLpAccpuntList] = useState([]);

  const selectBlockInfoByPage = async (lpAddress: string, pages: number) => {
    if (wallet && network) {
      const transActivity = new TransActivity([], network.chainId, 0);
      const list = await transActivity.getEventList(network, lpAddress, pages, pageSize)
      const counts = await transActivity.getEventListAll(network, lpAddress)
      const count = counts.length
      if ((count % pageSize) === 0) {
        setCount(count / pageSize);
      } else {
        setCount(Math.ceil(count / pageSize));
      }
      setActivityList(list);
    }
  };

  const scanBlock = async () => {
    if (wallet && network && token?.contractList) {
      const contractLists = [{ address: '', symbol: '', decimal: '18', rpcUrl: '', name: '' }];
      contractLists.pop();
      token.contractList?.forEach((item: any) => {
        if (item.rpcUrl === network?.rpcUrl) {
          contractLists.push(item);
        }
      })
      const list: any[] = [];
      list.push({ name: '個人Account (個人)', address: wallet?.address });
      lpAccount?.walletList?.forEach((item: any) => {
        if (network?.chainId === item.chainId) {
          const lpAccountInfo = { name: '', address: '', permits: [], confirmers: [], required: '', walletList: [] }
          lpAccountInfo.name = item.name.concat(' (法人)');
          lpAccountInfo.address = item.address;
          lpAccountInfo.permits = item.permits;
          lpAccountInfo.confirmers = item.confirmers;
          lpAccountInfo.required = item.required;
          lpAccountInfo.walletList = item.walletList;
          list.push(lpAccountInfo);
        }
      })
      if (contractLists.length !== 0) {
        // 使用保存的nriProvider
        const block = await network.nriProvider.getBlockNumber();
        const transActivity = new TransActivity([], network.chainId, 0);
        contractLists.forEach(async (item: any) => {
          list.forEach(async (items: any) => {
            const scanRes = await transActivity.scan(item, block, network, items.address);
            if (scanRes?.isUpdate) {
              let selectAddresss = selectAddress;
              if (!selectAddresss) {
                selectAddresss = list[0].address;
              }
              const startCount = (page - 1) * pageSize;
              selectBlockInfoByPage(selectAddresss, startCount);
            }
          })

        })
      }
    }
  };

  const getActivityInfo = async () => {
    if (wallet?.address && network) {
      const transActivity = new TransActivity([], "", 0);
      const list = await transActivity.getEventList(network, wallet.address, 0, pageSize)
      setActivityList(list);
      const counts = await transActivity.getEventListAll(network, wallet.address)
      const count = counts.length
      if ((count % pageSize) === 0) {
        setCount(count / pageSize);
      } else {
        setCount(Math.ceil(count / pageSize));
      }
    }
  };

  useEffect(() => {
    getActivityInfo()
    if (lpAccount && wallet?.address) {
      const list = [];
      list.push({ name: '個人Account (個人)', address: wallet?.address });
      lpAccount.walletList?.forEach((item: any) => {
        if (network?.chainId === item.chainId) {
          const lpAccountInfo = { name: '', address: '', permits: [], confirmers: [], required: '', walletList: [] }
          lpAccountInfo.name = item.name.concat(' (法人)');
          lpAccountInfo.address = item.address;
          lpAccountInfo.permits = item.permits;
          lpAccountInfo.confirmers = item.confirmers;
          lpAccountInfo.required = item.required;
          lpAccountInfo.walletList = item.walletList;
          list.push(lpAccountInfo);
        }
      })
      if (list.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setLpAccpuntList(list);
        setSelectAddress(list[0].address);
      }

    }
  }, [wallet, lpAccount]);

  useEffect(() => {
    const InterVal = setInterval(() => {
      scanBlock();
    }, 5000)
    return () => {
      clearInterval(InterVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, lpAccount, selectAddress, page]);

  const showSnackbar = (msg: string) => {
    setSnackbar(msg);
  };

  const closeSnackbar = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(undefined);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageSize(pageSize);
    setPage(value);
    const startCount = (value - 1) * pageSize;
    selectBlockInfoByPage(selectAddress, startCount);
  };

  const openTransInfo = async (event: TransActivityModel) => {
    setOpen(true);
    const values = Number(event.value);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const valueInt = parseInt(event.value, 10);
    if ((values - valueInt) > 0) {
      setTransValue(values)
    } else {
      setTransValue(valueInt)
    }
    setActivity(event);
  };

  const closeTransInfo = () => {
    setOpen(false);
    setActivity(undefined);
    setTransValue(0)
  };

  const copyHash = (transactionHash: any) => {
    copy(transactionHash);
    showSnackbar('コピーに成功しました。');
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectAddress(event.target.value as string);
    selectBlockInfoByPage(event.target.value, 0);
    setPage(1);
  };

  if (!network || !wallet) {
    return <Container />;
  }

  return (
    <>
      <Container maxWidth="md" sx={{ paddingX: '8px' }}>
        <Typography variant="h6" sx={{ marginTop: '16px' }}>
          アクティビティ
        </Typography>
        <div style={{ color: 'rgb(25 118 210)', float: 'right', marginTop: '-32px', cursor: 'pointer', width: '22%' }}>
          {
            lpAccountList.length > 0 ?
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectAddress}
                style={{ height: 30, float: 'right', width: '100%' }}
                onChange={handleSelectChange}
              >
                {
                  lpAccountList.map((item: any) => (
                    <MenuItem key={item.address} value={item.address} >{item.name}</MenuItem>
                  ))
                }
              </Select>
              :
              null
          }

        </div>
        <div style={{ margin: '20px auto' }}><span>{selectAddress}</span></div>
        {activityList.map((row) => (
          <div key={row.transactionHash}>
            <Card variant="outlined" sx={{ marginTop: '8px', cursor: 'pointer' }} onClick={() => openTransInfo(row)}>
              <CardContent>
                <div>{row.from === selectAddress ? <span style={{ fontSize: "large", color: 'green' }}>送金 {row.symbol}</span> : <span style={{ fontSize: "large", color: 'red' }}>受領 {row.symbol}</span>}{row.from === selectAddress ? <div style={{ float: 'right', fontSize: "large", color: 'green' }}><span>{'-'}{row.value} {row.symbol}</span></div> : <div style={{ float: 'right', fontSize: "large", color: 'red' }}><span>{'+'}{row.value} {row.symbol}</span></div>}</div>
                <Typography fontSize={12}>
                  {dayjs.unix(row.transTime).format('YYYY-MM-DD HH:mm:ss')} · {row.from === selectAddress ? '移動先：' : '移動元：'}{row.from === selectAddress ? row.to : row.from}
                </Typography>
              </CardContent>
            </Card>
            <Divider sx={{ marginY: '10px' }} />

          </div>
        )
        )}
        {
          conut === 0 ? '' :
            <Stack spacing={2}>
              <Pagination page={page} count={conut} onChange={handleChange} color="primary" />
            </Stack>

        }
        <br />
        <br />
        <br />
      </Container>
      <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
          {snackbar}
        </Alert>
      </Snackbar>
      <div>
        <BootstrapDialog
          open={open}
          onClose={closeTransInfo}
          aria-labelledby="customized-dialog-title"
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={closeTransInfo}>
            {activity?.from === selectAddress ? '送金 ' : '受領 '}{activity?.symbol}
          </BootstrapDialogTitle>
          <DialogContent>
            <div style={{ minWidth: 560 }}>
              <div style={{ float: 'left' }}>
                <span style={{ color: '#24272a', fontWeight: 'bold' }}>ステータス</span>
                <br />
                <span style={{ color: '#28a745' }}>確認されました</span>
              </div>

              <div style={{ float: 'right' }}>
                <a style={{ float: 'right', color: '#0376c9', fontSize: 15, cursor: 'pointer' }} onClick={() => copyHash(activity?.transactionHash)}>トランザクションIDをコピー</a>
                <br />
                <a style={{ float: 'right', color: 'black', fontSize: 15 }}>{activity ? dayjs.unix(activity?.transTime).format('YYYY-MM-DD HH:mm:ss') : ''}</a>
              </div>
            </div>
            <div style={{ clear: 'both' }} />
            <br />
            <div>
              <div>
                <div style={{ color: '#24272a', fontWeight: 'bold' }}>移動元</div>
                <div style={{ color: 'black', fontSize: 15 }}>{activity?.from}</div>
              </div>
              <br />
              <div>
                <div style={{ color: '#24272a', fontWeight: 'bold' }}>移動先</div>
                <div style={{ color: 'black', fontSize: 15 }}>{activity?.to}</div>
              </div>
              <br />
              <div>
                <div style={{ color: '#24272a', fontWeight: 'bold', float: 'left' }}>金額</div>
                <div style={{ float: 'right' }}>{activity?.from === selectAddress ? '- ' : '+ '}{transValue} {activity?.symbol}</div>
              </div>
              <div style={{ clear: 'both' }} />
              <br />
            </div>

          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}

export default PageTransActivity;
