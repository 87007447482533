// import { Wallet, ethers } from 'ethers';

import { dexieDb } from '../dexie';
import { Network } from './network';
import { TransActivity } from './transActivity';

export class Wallets {
  public address: any;

  public walletList: any;

  public transActivity?: TransActivity;

  static KEYS = {
    ADDRESS: 'ADDRESS',
    WALLETLIST: 'WALLETLIST',
  };

  async loadActivity(network: Network, contractAddress: string) {
    if (this.address) {
      this.transActivity = await TransActivity.load(this.address, network, contractAddress);
    }
  }

  async setWallet(network: Network, contractAddress: string) {
    await this.loadActivity(network, contractAddress);
  }

  async load(network: Network) {
    const address = await dexieDb.wallet.get(Wallets.KEYS.ADDRESS);
    if (address) {
      this.address = address.value as string;
    }

    const walletList = await dexieDb.wallet.get(Wallets.KEYS.WALLETLIST);
    if (walletList) {
      this.walletList = walletList.value as any;
    }
    this.loadActivity(network, '');
  }

  async save() {
    await dexieDb.wallet.put({ key: Wallets.KEYS.ADDRESS, value: this.address });
    await dexieDb.wallet.put({ key: Wallets.KEYS.WALLETLIST, value: this.walletList });
  }

  static async load(network: Network) {
    const wallet = new Wallets();
    await wallet.load(network);
    return wallet;
  }

  async clear() {
    await dexieDb.wallet.bulkDelete(Object.keys(Wallets.KEYS));
    this.transActivity = undefined;
  }
}

export default Wallets;
